import React from "react";
import { Actions } from "./actions";

import { AppState } from "./app-state";
import { AppContext, DispatchType } from "./context";

export interface ConnectedProps {
    actions: Actions;
    dispatch: DispatchType;
}

function mapStateToProps<P extends ConnectedProps & U, U = {}>(Component: React.ComponentType<P>) {
    return (mapFn: (state: AppState) => Omit<P, "dispatch" | "actions">): React.FunctionComponent<U> => {
        return (ownProps: U) => (
            <AppContext.Consumer>
                {({ state, dispatch, actions }) => {
                    const props = mapFn(state);
                    const CompX = Component as any;
                    return <CompX {...props} {...ownProps} dispatch={dispatch} actions={actions} />;
                }}
            </AppContext.Consumer>
        );
    };
}

export { mapStateToProps };
