import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { formatTableCell } from "../number-format";
import { getLocalizationKeyFromString } from "../shared-utils";
import { SimpleData } from "../types";

export interface TableContentProps {
    data: SimpleData;
}

export const TableContent: FC<TableContentProps> = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <table className="table table-content">
                <thead>
                    <tr>
                        <th />
                        {data.headers?.map((header) => {
                            return (
                                <th key={header}>
                                    {t(getLocalizationKeyFromString(header), { defaultValue: header })}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.rows.map((row, i) => {
                        if (row.name === "-" && row.data.every((val) => val === "-")) {
                            return (
                                <tr key={i}>
                                    <td colSpan={row.data.length + 1} />
                                </tr>
                            );
                        }

                        return (
                            <tr key={i}>
                                <td>{t(getLocalizationKeyFromString(row.name), row.name)}</td>
                                {row.data.map((col, j) => (
                                    <td key={j}>{formatTableCell(col)}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Fragment>
    );
};
