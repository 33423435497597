import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SubCat, SubCatSpec } from "../types";
import { GenericFilter } from "./generic-filter";
import { subCats as allSubCats } from "../segments";

export interface SubCatFilterProps {
    subCats: SubCat[];
    filteredSubCats: Array<string>;
    onChange: (id: string) => void;
    includeAll: () => void;
    excludeAll: () => void;
}

export const SubCatFilter: FC<SubCatFilterProps> = (props) => {
    const { t } = useTranslation();

    const { subCats, filteredSubCats, ...rest } = props;
    return (
        <GenericFilter
            title={t("Filter_by_sub_category", "Filter by sub-category")}
            getSpec={(id) => allSubCats.get(id) as SubCatSpec}
            segments={subCats}
            filteredSegments={filteredSubCats}
            {...rest}
        />
    );
};
