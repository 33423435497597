import { CatSpec, DetailLevel, SectorSpec, SubCatSpec } from "./types";

// This is a fake T function, that creates subcategories.
// Do not rename, GHG lexer parses the default text values out from this file.
function t<T extends object>(localizationField: string, name: string, idxColor: number, rest?: T): SectorSpec & T {
    const [, id] = localizationField.split(".");
    return {
        id,
        name,
        idxColor,
        ...rest
    } as any;
}

const arrSectors: SectorSpec[] = [
    t("header.energy", "Energy", 0),
    t("header.transport", "Transport", 1),
    t("header.ind-proc", "Industrial processes and product use", 2),
    t("header.agri", "Agriculture", 3),
    t("header.lulucf", "Land use, land-use change and forestry", 4),
    t("header.waste", "Waste", 5)
];

export const sectors: Map<string, SectorSpec> = new Map();
arrSectors.forEach((spec) => sectors.set(spec.id, spec));

const arrCats: CatSpec[] = [
    t("header.energy-ind", "Energy industries", 0, { sectorId: "energy" }),
    t("header.manuf", "Manufacturing industries and construction", 1, { sectorId: "energy" }),
    t("header.transport", "Transport", 2, { sectorId: "energy" }),
    t("header.other-sec", "Other sectors", 3, { sectorId: "energy" }),
    t("header.other", "Other", 4, { sectorId: "energy" }),
    t("header.oil", "Oil and natural gas and other emissions from energy production", 5, { sectorId: "energy" }),
    t("header.mineral-ind", "Mineral industry", 6, { sectorId: "ind-proc" }),
    t("header.chem-ind", "Chemical industry (Ammonia production)", 7, { sectorId: "ind-proc" }),
    t("header.metal-ind", "Metal industry (Lead production)", 8, { sectorId: "ind-proc" }),
    t("header.non-energy", "Non-energy products from fuels and solvent use", 9, { sectorId: "ind-proc" }),
    t("header.ods", "Product uses as ODS substitutes", 10, { sectorId: "ind-proc" }),
    t("header.other-prod", "Other product manufacture and use", 11, { sectorId: "ind-proc" }),
    t("header.enteric", "Enteric fermentation", 12, { sectorId: "agri" }),
    t("header.manure", "Manure management", 13, { sectorId: "agri" }),
    t("header.agri-soils", "Agricultural soils", 14, { sectorId: "agri" }),
    t("header.liming", "Liming", 15, { sectorId: "agri" }),
    t("header.urea", "Urea application", 16, { sectorId: "agri" }),
    t("header.forest", "Forest land", 17, { sectorId: "lulucf" }),
    t("header.cropland", "Cropland", 18, { sectorId: "lulucf" }),
    t("header.grassland", "Grassland", 19, { sectorId: "lulucf" }),
    t("header.wetlands", "Wetlands", 20, { sectorId: "lulucf" }),
    t("header.settlements", "Settlements", 21, { sectorId: "lulucf" }),
    t("header.other-land", "Other land", 22, { sectorId: "lulucf" }),
    t("header.harv-wood", "Harvested wood products", 23, { sectorId: "lulucf" }),
    t("header.other-lulucf", "Other", 24, { sectorId: "lulucf" }),
    t("header.disposal", "Solid waste disposal", 25, { sectorId: "waste" }),
    t("header.bio-treat", "Biological treatment of solid waste", 26, { sectorId: "waste" }),
    t("header.incineration", "Incineration and open burning of waste", 27, { sectorId: "waste" }),
    t("header.waste-water", "Waste water treatment and discharge", 28, { sectorId: "waste" })
];

export const cats: Map<string, CatSpec> = new Map();
arrCats.forEach((spec) => cats.set(spec.id, spec));

const arrSubCats: SubCatSpec[] = [
    t("header.pub-electric", "Public electricity production", 0, { catId: "energy-ind" }),
    t("header.pub-heat", "Public heat production", 1, { catId: "energy-ind" }),
    t("header.shale", "Shale oil production", 2, { catId: "energy-ind" }),
    t("header.iron-steel", "Iron and steel", 3, { catId: "manuf" }),
    t("header.non-ferr", "Non-ferrous metals", 4, { catId: "manuf" }),
    t("header.chemicals", "Chemicals", 5, { catId: "manuf" }),
    t("header.pulp", "Pulp, paper and print", 6, { catId: "manuf" }),
    t("header.food-proc", "Food processing, beverages and tobacco", 7, { catId: "manuf" }),
    t("header.non-met", "Non-metallic minerals", 8, { catId: "manuf" }),
    t("header.other-manuf", "Other", 9, { catId: "manuf" }),
    t("header.domestic-avi", "Domestic aviation", 10, { catId: "transport" }),
    t("header.road", "Road transportation", 11, { catId: "transport" }),
    t("header.rail", "Railways", 12, { catId: "transport" }),
    t("header.domestic-navi", "Domestic navigation", 13, { catId: "transport" }),
    t("header.com", "Commercial/institutional", 14, { catId: "other-sec" }),
    t("header.resident", "Residential", 15, { catId: "other-sec" }),
    t("header.agri-forest", "Agriculture/forestry/fishing", 16, { catId: "other-sec" }),
    t("header.cement", "Cement production", 17, { catId: "mineral-ind" }),
    t("header.lime", "Lime production", 18, { catId: "mineral-ind" }),
    t("header.glass", "Glass production", 19, { catId: "mineral-ind" }),
    t("header.other-carb", "Other process uses of carbonates", 20, { catId: "mineral-ind" }),
    t("header.lubricant", "Lubricant use", 21, { catId: "non-energy" }),
    t("header.paraffin", "Paraffin wax use", 22, { catId: "non-energy" }),
    t("header.solvent", "Solvent use", 23, { catId: "non-energy" }),
    t("header.paving", "Road paving with asphalt", 24, { catId: "non-energy" }),
    t("header.urea-catalysts", "Urea based catalysts for motor vehicle", 25, { catId: "non-energy" }),
    t("header.refrigeration", "Refrigeration and air conditioning", 26, { catId: "ods" }),
    t("header.foam", "Foam blowing agents", 27, { catId: "ods" }),
    t("header.fire-protection", "Fire protection", 28, { catId: "ods" }),
    t("header.aerosols", "Aerosols", 29, { catId: "ods" }),
    t("header.adia", "Adiabatic Properties: Shoes and Tyres", 30, { catId: "ods" }),
    t("header.med-app", "N₂O from product uses from medical applications", 31, { catId: "other-prod" }),
    t("header.other-prod-use", "SF₆ and PFCs from other product use", 32, { catId: "other-prod" }),
    t("header.electrical-equip", "Electrical Equipment", 33, { catId: "other-prod" }),
    t("header.other-prod-sf6", "SF₆ from other product use", 34, { catId: "other-prod" })
];

export const subCats: Map<string, SubCatSpec> = new Map();
arrSubCats.forEach((spec) => subCats.set(spec.id, spec));

export const getSegmentSpec = (detailLevel: DetailLevel, id: string): SectorSpec => {
    // TODO: throw error if no segment spec with this id exists?
    if (detailLevel === DetailLevel.sector) {
        return sectors.get(id) as SectorSpec;
    } else if (detailLevel === DetailLevel.cat) {
        return cats.get(id) as SectorSpec;
    } else if (detailLevel === DetailLevel.subCat) {
        return subCats.get(id) as SectorSpec;
    } else {
        throw new Error("Invalid detail level: " + detailLevel);
    }
};
