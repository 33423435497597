import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

const COOKIE_ACCEPT_KEY = "COOKIES_OK";

export const CookiesNotification: FC = () => {
    const [cookiesAccepted, setCookiesAccepted] = useState(Boolean(localStorage.getItem(COOKIE_ACCEPT_KEY)));
    const { t } = useTranslation();

    const handleCookiesAccepted = () => {
        localStorage.setItem(COOKIE_ACCEPT_KEY, "1");
        setCookiesAccepted(true);
    };
    if (cookiesAccepted) {
        return null;
    }

    return (
        <div className="notification is-info is-clearfix" style={{ position: "sticky", bottom: 0 }}>
            {t(
                "cookies_notification",
                "This website uses cookies to enhance your site experience and for analytical purposes. By proceeding on this website you are OK with this."
            )}
            <button className="button is-pulled-right" onClick={handleCookiesAccepted}>
                {t("Close", "Close")}
            </button>
        </div>
    );
};
