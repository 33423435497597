import { Component, MouseEvent as ReactMouseEvent, Fragment, createRef } from "react";
import { Canvas } from "../canvas";

import { black, getConsecutiveColor } from "../colors";
import { distance } from "../geometry";
import { sectors as sectorSpecs } from "../segments";
import { Proportion, SectorSpec, TooltipProps } from "../types";
import { Tooltip } from "./tooltip";
import { YearSelect } from "./year-select";

interface Props {
    proportion: Proportion;
}

interface State {
    idxYear: number;
    tooltip?: TooltipProps;
}

interface PieSector {
    sectorId: string;
    percentage: number;
    startAngle: number;
    endAngle: number;
}

const sectorIds = ["energy", "transport", "ind-proc", "agri", "waste"];

const tau = 2 * Math.PI;

const width = 800;
const height = 600;

const center = {
    x: 0.5 * width,
    y: 0.5 * height
};

const radius = 0.33 * height;
const origAngle = -0.5 * Math.PI;

const yearY = 75;
const font = "30px Arial";

const intervalMs = 2000;

export class PieChart extends Component<Props, State> {
    state: State = {
        idxYear: 0
    };

    private _isMounted: boolean = false;

    // @ts-ignore
    private ctx: CanvasRenderingContext2D;

    private intervalId: number = 0;

    private pieSectors: PieSector[] = [];

    private highlitPieSector?: PieSector;

    private canvasRef: React.RefObject<HTMLCanvasElement> = createRef();

    componentDidMount() {
        this._isMounted = true;

        const canvas = this.canvasRef.current as HTMLCanvasElement;
        this.ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        this.ctx.font = font;
        this.ctx.textAlign = "center";
        this.renderGraph();
        this.intervalId = window.setInterval(() => this.updateYear(), intervalMs);
    }

    componentDidUpdate() {
        this.renderGraph();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    public setTooltip = (tooltip?: TooltipProps) => {
        this.setState({ tooltip });
    };

    private updateYear() {
        const { idxYear } = this.state;

        if (this._isMounted && idxYear < this.props.proportion.numYears - 1) {
            this.highlitPieSector = undefined;
            this.removeTooltip();
            this.setState({ idxYear: idxYear + 1 });
        } else {
            window.clearInterval(this.intervalId);
        }
    }

    private getPieSectorUnderPoint(x: number, y: number): PieSector | undefined {
        const centerPointDistance = distance(x, y, center.x, center.y);

        if (centerPointDistance > radius) {
            return undefined;
        }

        const relX = x - center.x;
        const relY = y - center.y;
        let angle = Math.atan2(relY, relX);

        // Workaround, because our origin angle is -0.5PI (top) not 0 (right)
        if (relX < 0 && relY < 0) {
            angle += tau;
        }

        return this.pieSectors.find(({ startAngle, endAngle }) => angle > startAngle && angle <= endAngle);
    }

    private handleMouseMove(evt: ReactMouseEvent<HTMLElement, MouseEvent>) {
        const { canvas } = this.ctx;
        const { top, left } = canvas.getBoundingClientRect();
        const x = evt.clientX - left;
        const y = evt.clientY - top;
        const pieSector = this.getPieSectorUnderPoint(x, y);

        if (pieSector) {
            this.highlitPieSector = pieSector;

            this.setTooltip({
                x: evt.clientX,
                y: evt.clientY,
                text: pieSector.percentage + "%"
            });
        } else {
            this.highlitPieSector = undefined;
            this.removeTooltip();
        }
    }

    private removeTooltip() {
        this.setTooltip();
    }

    private clearCanvas() {
        this.ctx.clearRect(0, 0, width, height);
    }

    private renderYear() {
        const {
            ctx,
            props: {
                proportion: { startYear }
            },
            state: { idxYear }
        } = this;
        const year = startYear + idxYear;
        ctx.strokeStyle = black;
        ctx.fillStyle = black;
        ctx.fillText(year.toString(), center.x, yearY);
    }

    private drawPieSector(pieSector: PieSector, color: string) {
        const { ctx } = this;
        const { startAngle, endAngle } = pieSector;

        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.moveTo(center.x, center.y);
        ctx.arc(center.x, center.y, radius, startAngle, endAngle);
        ctx.lineTo(center.x, center.y);
        ctx.closePath();
        ctx.fill();
    }

    private renderPie() {
        const {
            props: {
                proportion: { sectors }
            },
            state: { idxYear }
        } = this;

        this.pieSectors = [];

        let angle = origAngle;

        sectorIds.forEach((id) => {
            const { idxColor } = sectorSpecs.get(id) as SectorSpec;
            const color = getConsecutiveColor(idxColor);

            const share = sectors[id][idxYear] as number;
            const angleShare = (share / 100) * tau;

            const startAngle = angle;
            const endAngle = angle + angleShare;
            const pieSector = { sectorId: id, startAngle, endAngle, percentage: share };
            this.pieSectors.push(pieSector);

            this.drawPieSector(pieSector, color);

            angle = endAngle;
        });
    }

    private highlightPieSector() {
        if (!this.highlitPieSector) {
            return;
        }

        this.drawPieSector(this.highlitPieSector, "#ffffff88");
    }

    private renderGraph() {
        this.clearCanvas();
        this.renderYear();
        this.renderPie();
        this.highlightPieSector();
    }

    private selectYear(idxYear: number) {
        window.clearInterval(this.intervalId);
        this.highlitPieSector = undefined;
        this.removeTooltip();
        this.setState({ idxYear });
    }

    private renderTooltip() {
        const { tooltip } = this.state;

        if (!tooltip || !tooltip.text) return;

        return <Tooltip {...tooltip} />;
    }

    render() {
        const { startYear, numYears } = this.props.proportion;

        return (
            <Fragment>
                <YearSelect
                    startYear={startYear}
                    numYears={numYears}
                    value={this.state.idxYear}
                    onSelect={(idxYear) => this.selectYear(idxYear)}
                />
                <div className="auto-scroll">
                    <Canvas
                        canvasRef={this.canvasRef}
                        width={width}
                        height={height}
                        onMouseMove={(evt: ReactMouseEvent<HTMLElement, MouseEvent>) => this.handleMouseMove(evt)}
                    />
                </div>
                {this.renderTooltip()}
            </Fragment>
        );
    }
}
