import { FC, Fragment } from "react";
import ReactMarkdown from "react-markdown";
import { GhgContent } from "./ghg-content";

export interface MarkdownContentProps {
    markdown: string;
}

export const MarkdownContent: FC<MarkdownContentProps> = ({ markdown }) => {
    return (
        <Fragment>
            <div className="markdown-container content has-text-justified">
                <ReactMarkdown
                    components={{
                        code({ node, inline, className, children, ...props }) {
                            const contentItem =
                                (Array.isArray(children) && children[children.length - 1]) ||
                                (typeof children === "string" && children);
                            const contentStr = (typeof contentItem === "string" && contentItem.trim()) || "";
                            const isGhg = contentStr && contentStr.endsWith(".yml") && contentStr.startsWith("ghg:");
                            if (!isGhg) {
                                return (
                                    <code className={className} {...props}>
                                        {children}
                                    </code>
                                );
                            }

                            const ghgFile = contentStr.substr(4);
                            return (
                                <div className="markdown-ghg">
                                    <GhgContent file={ghgFile} />
                                </div>
                            );
                        },
                        pre: "div"
                    }}
                >
                    {markdown}
                </ReactMarkdown>
            </div>
        </Fragment>
    );
};
