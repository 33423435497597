import { FC } from "react";
import { LegendItem } from "./legend-item";

interface Props {
    labels: string[];
}

export const SimpleLegend: FC<Props> = ({ labels }) => (
    <ul className="legend">
        {labels.map((label, idx) => (
            <LegendItem key={idx} idxColor={idx}>
                {label}{" "}
            </LegendItem>
        ))}
    </ul>
);
