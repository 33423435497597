import React, { Component, MouseEvent as ReactMouseEvent, createRef } from "react";

import { TooltipProps, SimpleData, Bounds, Bar, LineSegment, Row, ContentType } from "../types";
import { black, getConsecutiveColor } from "../colors";
import { formatGraphStepNumber } from "../number-format";
import { graphLineWidth, pointRadius } from "./const";
import { Canvas } from "../canvas";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { getFileFieldName, getLocalizationKeyFromString } from "../shared-utils";
import { distance } from "../geometry";
import { createPngDataUrl } from "../export-png";
import { downloadURI } from "../download-uri";

export interface GenericChartProps {
    data: SimpleData;
    setTooltip: (tip?: TooltipProps) => void;
    colorIndexes?: number[];
    onExportPng?: (cb: Function) => void;
}

const width = 800;
const height = 600;
const paddingTop = -10;
const paddingBottom = 30;
const paddingLeft = 60;
const paddingRight = 20;
const paddingText = 3;
const minBarHeight = 5;
const scaleLineWidth = 1;
const topGrouperHeight = 8;

interface ChartRenderSpec {
    bounds: Bounds;
    amplitude: number;
    yBase: number;
    valStep: number;
}

enum ChartContentType {
    bars = "bars",
    lines = "lines"
}

class GenericChartImpl extends Component<GenericChartProps & WithTranslationProps> {
    // @ts-ignore
    private ctx: CanvasRenderingContext2D;

    private renderSpec: { bars: ChartRenderSpec; lines: ChartRenderSpec } = {
        [ChartContentType.bars]: { amplitude: 0, yBase: 0, valStep: 0, bounds: { min: 0, max: 0 } },
        [ChartContentType.lines]: { amplitude: 0, yBase: 0, valStep: 0, bounds: { min: 0, max: 0 } }
    };

    private bars: Bar[] = [];

    private lines: LineSegment[] = [];

    private highlightBar?: Bar;

    private highlightLine?: LineSegment;

    private canvasRef: React.RefObject<HTMLCanvasElement> = createRef();

    componentDidMount() {
        const canvas = this.canvasRef.current;
        if (!canvas) {
            throw new Error("No canvas ref");
        }
        this.ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        this.renderGraph();

        const { onExportPng } = this.props;
        if (onExportPng) {
            onExportPng(this.exportAsPng);
        }
    }

    componentDidUpdate() {
        this.renderGraph();
    }

    private handleMouseMove(evt: ReactMouseEvent<HTMLElement, MouseEvent>) {
        const { canvas } = this.ctx;
        const { top, left } = canvas.getBoundingClientRect();
        const x = evt.clientX - left;
        const y = evt.clientY - top;
        const bar = this.findBar(x, y);
        const line = this.findLineSegment(x, y);

        const highlightItem = line || bar || undefined;
        if (highlightItem) {
            if (line) {
                this.highlightLine = line;
                this.highlightBar = undefined;
            } else if (bar) {
                this.highlightBar = bar;
            }

            const legendText = this.props.i18n?.t(getLocalizationKeyFromString(highlightItem.row?.name || "N/A"), {
                defaultValue: highlightItem.row?.name
            });
            const tooltipText =
                this.props.i18n?.t("chart_tooltip_text", {
                    defaultValue: "{{legend}} - {{value}}",
                    value: highlightItem.amt.toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    legend: legendText
                }) || highlightItem.amt.toString();
            this.props.setTooltip({ x: evt.clientX, y: evt.clientY, text: tooltipText });
        } else {
            this.highlightBar = undefined;
            this.highlightLine = undefined;
            this.removeTooltip();
        }
    }

    private get paddingTop() {
        if (this.props.data.projections_year) {
            return paddingTop + topGrouperHeight;
        }

        return paddingTop;
    }

    private get paddingBottom() {
        return paddingBottom;
    }

    private get paddingLeft() {
        return paddingLeft;
    }

    private get paddingRight() {
        if (this.hasBarsAndLines()) return paddingLeft;

        return paddingRight;
    }

    private findBar(x: number, y: number): Bar | undefined {
        return this.bars.find((bar) => {
            return x >= bar.left && x <= bar.right && y >= bar.top && y <= bar.bottom;
        });
    }

    private findLineSegment(x: number, y: number): LineSegment | undefined {
        return this.lines.find((line) => {
            return distance(x, y, line.x, line.y) <= pointRadius;
        });
    }

    private removeTooltip() {
        this.props.setTooltip();
    }

    private calculateBounds(type: ChartContentType): Bounds {
        const { years, rows, line_items_no_y } = this.props.data;

        const posSums: number[] = [];
        const negSums: number[] = [];

        const typeRows = rows.filter(
            (row) =>
                line_items_no_y ||
                (type === ChartContentType.lines && this.isLineRow(row)) ||
                (type === ChartContentType.bars && !this.isLineRow(row))
        );

        years.forEach((year, idx) => {
            const posSum = typeRows.reduce((aggr, { data }) => {
                const val = data[idx];
                if (typeof val !== "number" || val <= 0) return aggr;

                // aggregate all bars by year
                if (type === ChartContentType.bars) {
                    return aggr + val;
                }
                // find max value
                else {
                    return aggr > val ? aggr : val;
                }
            }, 0);

            posSums.push(posSum);

            const negSum = typeRows.reduce((aggr, { data }) => {
                const val = data[idx];
                if (typeof val !== "number" || val > 0) return aggr;

                // aggregate all bars by year
                if (type === ChartContentType.bars) {
                    return aggr + val;
                }
                // find max value
                else {
                    return aggr < val ? aggr : val;
                }
            }, 0);

            negSums.push(negSum);
        });

        return {
            max: Math.max(...posSums),
            min: Math.min(...negSums)
        };
    }

    private calculateAmplitude(type: ChartContentType): number {
        const { min, max } = this.renderSpec[type].bounds;
        const nominalAmplitude = max - min;
        return 1.1 * nominalAmplitude;
    }

    private calculateYBase(type: ChartContentType): number {
        return height - this.paddingBottom - this.calculateBarHeight(this.renderSpec[type].bounds.min, type);
    }

    private calculateLineStep(type: ChartContentType): number {
        const { amplitude } = this.renderSpec[type];
        const approximateMagnitude = Math.log10(amplitude);
        const magnitude = Math.floor(approximateMagnitude);
        const valStepCandidate = Math.pow(10, magnitude);

        if (2 * valStepCandidate < amplitude) {
            return valStepCandidate;
        } else {
            return 0.25 * valStepCandidate;
        }
    }

    private clearCanvas() {
        this.ctx.clearRect(0, 0, width, height);
    }

    private calculateBarHeight(val: number, type: ChartContentType): number {
        const renderSpec = this.renderSpec[type];
        if (renderSpec.amplitude === 0) return 0;

        const maxHeight = height - (this.paddingTop + this.paddingBottom);
        const nominalHeight = (Math.abs(val) / renderSpec.amplitude) * maxHeight;
        return Math.max(nominalHeight, minBarHeight);
    }

    private renderYear(year: number, x: number) {
        const { ctx } = this;

        ctx.save();
        ctx.fillStyle = black;
        ctx.textBaseline = "middle";
        const y = height - 0.25 * this.paddingBottom;
        ctx.translate(x, y);
        ctx.rotate(-0.5 * Math.PI);
        ctx.fillText(year.toString(), 0, 0);
        ctx.restore();
    }

    private renderHorizontalLine(y: number) {
        const { ctx } = this;

        ctx.beginPath();
        ctx.moveTo(0.5 * this.paddingLeft, y);
        ctx.lineTo(width - this.paddingRight, y);
        ctx.stroke();
    }

    private renderHorizontalLineText(val: number, y: number, left = true) {
        const strVal = Math.abs(val) < 1 ? val.toPrecision(1) : formatGraphStepNumber(val);
        const textWidth = this.ctx.measureText(strVal).width;

        const x = left
            ? Math.max(paddingText, this.paddingLeft - textWidth - paddingText)
            : width - textWidth - paddingRight;
        this.ctx.fillText(strVal, x, y - paddingText);
    }

    private renderHorizontalLines() {
        const { ctx } = this;
        const { type, line_items_no_y } = this.props.data;
        const mainContentType = type === ContentType.lineChart ? ChartContentType.lines : ChartContentType.bars;

        const { yBase, valStep, bounds } = this.renderSpec[mainContentType];

        ctx.strokeStyle = black;
        ctx.fillStyle = black;
        ctx.lineWidth = scaleLineWidth;

        let val = 0;
        let y = yBase;
        const yStep = this.calculateBarHeight(valStep, mainContentType);

        while (valStep !== 0 && val <= bounds.max) {
            this.renderHorizontalLine(y);
            this.renderHorizontalLineText(val, y);

            val += valStep;
            y -= yStep;
        }

        val = -valStep;
        y = yBase + yStep;

        while (valStep !== 0 && val >= bounds.min) {
            this.renderHorizontalLine(y);
            this.renderHorizontalLineText(val, y);

            val -= valStep;
            y += yStep;
        }

        if (this.hasBarsAndLines() && !line_items_no_y) {
            const lineRenderSpec = this.renderSpec[ChartContentType.lines];
            const lineStepY = this.calculateBarHeight(lineRenderSpec.valStep, ChartContentType.lines);

            y = lineRenderSpec.yBase;
            val = 0;
            while (lineRenderSpec.valStep !== 0 && val <= lineRenderSpec.bounds.max) {
                if (val > 0) this.renderHorizontalLineText(val, y, false);

                val += lineRenderSpec.valStep;
                y -= lineStepY;
            }
        }
    }

    private hasBarsAndLines() {
        const lineRenderSpec = this.renderSpec[ChartContentType.lines];
        const mainContentType =
            this.props.data.type === ContentType.lineChart ? ChartContentType.lines : ChartContentType.bars;

        return mainContentType === ChartContentType.bars && !!lineRenderSpec.valStep;
    }

    private renderTopArc(x: number, y: number, w: number, h: number, label: string) {
        const { ctx } = this;

        const r = h / 2;
        const centerTop = { x: x + w / 2, y };

        ctx.beginPath();
        ctx.strokeStyle = black;

        // left side
        ctx.moveTo(x, y + h);
        ctx.arcTo(x, y + r, x + r, y + r, r);
        ctx.lineTo(centerTop.x - r, centerTop.y + r);
        ctx.arcTo(centerTop.x, centerTop.y + r, centerTop.x, centerTop.y, r);

        // right side
        ctx.arcTo(centerTop.x, centerTop.y + r, centerTop.x + r, centerTop.y + r, r);
        ctx.lineTo(x + w - r, centerTop.y + r);
        ctx.arcTo(x + w, y + r, x + w, y + h, r);

        ctx.stroke();

        // render text
        ctx.save();
        ctx.textBaseline = "bottom";
        ctx.fillStyle = black;
        // ctx.font.fontsize(30);
        ctx.font = "1rem sans-serif";
        const textSize = this.ctx.measureText(label);
        ctx.fillText(label, centerTop.x - textSize.width / 2, centerTop.y - 1);
        ctx.restore();
    }

    private fillHighlightBar() {
        if (!this.highlightBar) {
            return;
        }

        const { ctx } = this;
        const { top, bottom, left, right } = this.highlightBar;

        const width = right - left;
        const height = bottom - top;

        ctx.fillStyle = "#ffffff88";
        ctx.fillRect(left, top, width, height);
    }

    private fillHighlightLine() {
        if (!this.highlightLine) {
            return;
        }

        const { ctx } = this;
        const { x, y } = this.highlightLine;

        ctx.fillStyle = "#ffffff88";
        ctx.strokeStyle = "#ffffff88";
        this.drawPoint(x, y);
    }

    private isLineRow(row: Row): boolean {
        const { line_items, type } = this.props.data;
        if (type === ContentType.lineChart) return true;

        const item = line_items && line_items.find((rowName) => rowName === row.name);
        return !!item;
    }

    private getBarWidth(): number {
        const { years } = this.props.data;
        return (width - (this.paddingLeft + this.paddingRight)) / years.length;
    }

    private renderBars() {
        const { ctx, props } = this;
        const { years, rows, type, unit } = props.data;
        const { yBase } = this.renderSpec[ChartContentType.bars];

        this.bars = [];

        if (type === ContentType.lineChart) return;
        const isPercentageChart = unit && (unit.indexOf('%') >= 0);
        const minTopY = isPercentageChart ? yBase - this.calculateBarHeight(100, ChartContentType.bars) : 0;

        const barWidth = this.getBarWidth();
        let x = this.paddingLeft;

        years.forEach((year, idxYear) => {
            let posY = yBase;
            let negY = yBase;

            const right = x + barWidth;

            // eslint-disable-next-line no-loop-func
            rows.forEach((row, idxRow) => {
                // render bar lines only
                if (this.isLineRow(row)) return;

                const val = row.data[idxYear] as number;

                const color = getConsecutiveColor(props.colorIndexes ? props.colorIndexes[idxRow] : idxRow);

                ctx.fillStyle = color;

                let barHeight = this.calculateBarHeight(val, ChartContentType.bars);

                let barTop: number = 0;
                let barBottom: number = 0;

                if (val > 0) {
                    barBottom = posY;
                    posY -= barHeight;
                    if (posY < minTopY) {
                       // if we go out of bounds with percentage chart, then fix the top
                       barHeight = barHeight - (minTopY - posY);
                       posY = minTopY;
                    }

                    barTop = posY;
                    ctx.fillRect(x, posY, barWidth, barHeight);
                } else if (val < 0) {
                    ctx.fillRect(x, negY, barWidth, barHeight);
                    barTop = negY;
                    negY += barHeight;
                    barBottom = negY;
                }

                const bar = { row, top: barTop, bottom: barBottom, left: x, right, amt: val };
                this.bars.push(bar);
            });

            x = right;
        });
    }

    private drawPoint(x: number, y: number) {
        const { ctx } = this;

        ctx.beginPath();
        ctx.arc(x, y, pointRadius, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
    }

    private drawLine(x1: number, y1: number, x2: number, y2: number) {
        const { ctx } = this;

        ctx.lineWidth = graphLineWidth;
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
    }

    private drawLineSegment(x: number, y: number, color: string, prevX?: number, prevY?: number) {
        const { ctx } = this;

        ctx.strokeStyle = color;
        ctx.fillStyle = color;
        this.drawPoint(x, y);

        if (prevX && prevY) {
            this.drawLine(prevX, prevY, x, y);
        }
    }

    private calculatePointY(val: number) {
        const height = this.calculateBarHeight(val, ChartContentType.lines);
        return this.renderSpec[ChartContentType.lines].yBase - height;
    }

    private renderLines() {
        const {
            props: { data, colorIndexes }
        } = this;

        this.lines = [];

        const barWidth = this.getBarWidth();

        data.rows.forEach((row, index) => {
            if (!this.isLineRow(row)) return;

            const color = getConsecutiveColor(colorIndexes && colorIndexes[index] ? colorIndexes[index] : index);
            let prevX: number;
            let prevY: number;
            let x = this.paddingLeft + 0.5 * barWidth;

            row.data.forEach((cellValue) => {
                if (cellValue) {
                    const y = this.calculatePointY(cellValue as number);
                    this.drawLineSegment(x, y, color, prevX, prevY);
                    prevY = y;
                    prevX = x;

                    this.lines.push({
                        x,
                        y,
                        row,
                        amt: cellValue as number
                    });
                }
                x += barWidth;
            });
        });
    }

    private renderLeftVerticalAxisLabel() {
        const { i18n, data } = this.props;
        if (!data.unit) return;

        const { ctx } = this;

        ctx.fillStyle = black;
        const x = 0.5 * this.paddingLeft - 15;
        const y = this.paddingTop + 0.5 * (height - this.paddingBottom - this.paddingTop);

        ctx.save();
        ctx.translate(x, y);
        ctx.rotate(-0.5 * Math.PI);
        const unitText = i18n?.t(getFileFieldName(data.id, "unit"), { defaultValue: data.unit }) || data.unit;
        ctx.fillText(unitText, 0, 0);
        ctx.restore();
    }

    private calculateRenderSpecs() {
        Object.values(ChartContentType).forEach((type) => {
            const renderSpec = this.renderSpec[type];
            renderSpec.bounds = this.calculateBounds(type);
            renderSpec.amplitude = this.calculateAmplitude(type);
            renderSpec.yBase = this.calculateYBase(type);
            renderSpec.valStep = this.calculateLineStep(type);
        });
    }

    private renderGraph() {
        this.calculateRenderSpecs();

        this.clearCanvas();
        this.renderBars();
        this.renderLines();

        this.renderYearsAxis();

        this.renderHorizontalLines();
        this.renderLeftVerticalAxisLabel();
        this.fillHighlightBar();
        this.fillHighlightLine();

        this.renderYearGrouperForProjections();
    }

    private renderYearGrouperForProjections() {
        const { data } = this.props;
        if (!data.projections_year) return;

        const inventoryLabel =
            this.props.i18n?.t("chart_inventory_group_label", {
                defaultValue: "Inventory"
            }) || "";
        const projectionsLabel =
            this.props.i18n?.t("chart_projections_group_label", {
                defaultValue: "Projections"
            }) || "";

        const y = this.paddingTop - topGrouperHeight + 30;

        const firstYearX = this.paddingLeft;
        const lastYearX = this.paddingLeft + data.years.length * this.getBarWidth();
        const projectionYearIndex = data.years.findIndex((year) => year.year === data.projections_year);

        const projectionsYearX = this.paddingLeft + projectionYearIndex * this.getBarWidth();

        const spaceBetween = 1;

        this.renderTopArc(
            firstYearX,
            y,
            projectionsYearX - firstYearX - spaceBetween,
            topGrouperHeight,
            inventoryLabel
        );
        this.renderTopArc(
            projectionsYearX + spaceBetween,
            y,
            lastYearX - projectionsYearX - spaceBetween,
            topGrouperHeight,
            projectionsLabel
        );
    }

    private renderYearsAxis() {
        const { years } = this.props.data;

        const barWidth = this.getBarWidth();
        let yearX = this.paddingLeft + barWidth * 0.5;
        years.forEach((year) => {
            this.renderYear(year.year, yearX);
            yearX += barWidth;
        });
    }

    render() {
        return (
            <Canvas
                canvasRef={this.canvasRef}
                width={width}
                height={height}
                onMouseMove={(evt: any) => this.handleMouseMove(evt)}
                onMouseLeave={() => this.removeTooltip()}
            />
        );
    }

    private exportAsPng = () => {
        const canvas = this.canvasRef.current;
        if (!canvas) {
            throw new Error("No canvas ref");
        }
        const dataUrl = createPngDataUrl(canvas, this.props.data);

        downloadURI(dataUrl, "chart.png");
    };
}

const GenericChart = withTranslation()(GenericChartImpl);

export { GenericChart };
