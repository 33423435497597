import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useMatch } from "react-router-dom";
import { ActivityContent } from "./comp/activity-content";
import { Spinner } from "./comp/spinner";
import { DynamicPageContentIndex } from "../details-types";
import { loadDynamicPageIndex } from "./data/dynamic-page-data";

export interface DynamicContentPageProps {
    dataDir: string;
    title: string;
    urlPrefix: string;
}


export const DynamicContentPage: FC<DynamicContentPageProps> = (props) => {
    const { dataDir, urlPrefix, title } = props;

    const [data, setData] = useState<DynamicPageContentIndex>();
    const pageMatch = useMatch(`${urlPrefix}/:subPageName`);
    let activeSubPageName = pageMatch && pageMatch.params.subPageName;

    const activityMatch = useMatch(`${urlPrefix}/:subPageName/:itemName`);
    const activeItemName = activityMatch && activityMatch.params.itemName;
    if (!activeSubPageName && activityMatch) {
        activeSubPageName = activityMatch.params.subPageName;
    }

    const { t } = useTranslation();

    useEffect(() => {
        const load = async () => {
            const projectsData = await loadDynamicPageIndex(dataDir);
            setData(projectsData);
        };

        load();
    });

    if (!data) {
        return <Spinner />;
    }

    const activePage =
        data.tabs.find((subPage) => {
            if (subPage.id !== activeSubPageName && activeSubPageName) return false;

            return true;
        }) || data.tabs[0];

    const menuSource = activePage.items;

    const activeItem =
        (menuSource &&
            menuSource.length > 0 &&
            (menuSource.find((activity) => activity.id === activeItemName) || menuSource[0])) ||
        undefined;

    const activePageMenuItems =
        menuSource &&
        menuSource.map((item) => {
            return (
                <li key={item.id}>
                    <Link
                        to={`${urlPrefix}/${activePage.id}/${item.id}`}
                        className={item === activeItem ? "is-active" : ""}
                    >
                        {t(item.id, item.title)}
                    </Link>
                </li>
            );
        });

    const tabLabels = data.tabs.map((tab) => {
        return (
            <li key={tab.id} className={`${activePage?.id === tab.id ? "is-active" : ""}`}>
                <Link to={`${urlPrefix}/${tab.id}`}>{t(tab.id, tab.title)}</Link>
            </li>
        );
    });

    return (
        <div className="section">
            <h1 className="subtitle">{title}</h1>
            <div className="dynamic-page-tabs-container tabs">
                <ul>{tabLabels}</ul>
            </div>
            <div className="dynamic-page-container columns container">
                {activePageMenuItems && activePageMenuItems.length > 1 && (
                    <div className="activity-menu column is-one-fifth">
                        <aside className="menu">
                            <ul className="menu-list">{activePageMenuItems}</ul>
                        </aside>
                    </div>
                )}
                <div className="activity-data column">
                    {activeItem && (
                        <ActivityContent
                            key={activeItem.file}
                            file={activeItem.file}
                            title={t(activeItem.id, activeItem.title)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
