import { Fragment } from "react";
import { HashRouter } from "react-router-dom";

import { Footer } from "./footer";
import { Header } from "./header";
import { AppRoutes } from "./app-routes";
import "./styles/all.sass";
import { CookiesNotification } from "./comp/cookies-notification";

export const Layout = () => {
    return (
        <Fragment>
            <HashRouter>
                <Header />
                {/* <iframe src="https://e.infogram.com/_/Teaw7B8MRB6bRFSMfZse?src=embed" title="veebika grafikud" width="1500" height="900" scrolling="no" frameBorder="0" style={{border: "none" }} allowFullScreen></iframe>                <AppRoutes /> */}
                <div className="section pt-1 pb-1 header-image-section">
                    <a href="https://showyourstripes.info/s/europe/estonia" target="_blank" rel="noreferrer">
                        <img className="header-img" src="stripes.png" alt="|||" />
                    </a>
                </div>
                <AppRoutes />
                <Footer />
                <CookiesNotification />
            </HashRouter>
        </Fragment>
    );
};
