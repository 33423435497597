/* eslint-disable no-loop-func */
import { black, getConsecutiveColor } from "./colors";
import { Row, SimpleData } from "./types";

const LEGEND_ITEM_HEIGHT = 30;
const LEGEND_BOX_SIZE = 20;
const LEGEND_BOX_SPACING = 10;
const LEGEND_ITEM_SPACING = 20;
const PADDING = 50;
const CANVAS_SIZE_COEF = 1.2;
const LEGEND_FONT = "14px Arial";

export function createPngDataUrl(srcCanvas: HTMLCanvasElement, data: SimpleData): string {
    const { width, height } = srcCanvas;

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height * CANVAS_SIZE_COEF;

    const ctx = canvas.getContext("2d");
    if (!ctx) {
        throw new Error("Unable to get context from canvas");
    }

    // white background
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // copy main chart
    ctx.drawImage(srcCanvas, 0, 10);

    // render legend
    renderLegend(data, ctx, PADDING, height + PADDING, canvas.width - PADDING * 2);

    const dataUrl = canvas.toDataURL();
    return dataUrl;
}

function renderLegend(data: SimpleData, ctx: CanvasRenderingContext2D, x: number, y: number, maxWidth: number) {
    function getItemWidth(text: string) {
        return LEGEND_BOX_SIZE + LEGEND_BOX_SPACING + ctx.measureText(text).width + LEGEND_ITEM_SPACING;
    }

    let lineY = y;
    let lineWidth = 0;
    let line: Row[] = [];
    ctx.font = LEGEND_FONT;

    function renderLegendLine() {
        let lineX = 0;
        lineX = x + (maxWidth - lineWidth) / 2;
        line.forEach((row, ix) => {
            const colorIx = row.colorIdx !== undefined ? row.colorIdx : ix;
            const color = getConsecutiveColor(colorIx);

            ctx.beginPath();
            ctx.fillStyle = color;
            ctx.rect(lineX, lineY, LEGEND_BOX_SIZE, LEGEND_BOX_SIZE);
            ctx.fill();

            const text = row.name;
            // const textSize = ctx.measureText(text);
            ctx.fillStyle = black;
            ctx.fillText(text, lineX + LEGEND_BOX_SIZE + LEGEND_BOX_SPACING, lineY + 15);

            lineX += getItemWidth(text);
        });

        lineY += LEGEND_ITEM_HEIGHT;
        lineWidth = 0;
        line = [];
    }

    let i = 0;
    while (i < data.rows.length) {
        const itemWidth = getItemWidth(data.rows[i].name);
        if (itemWidth + lineWidth < maxWidth) {
            line.push(data.rows[i]);
            lineWidth += itemWidth;
            i++;

            if (i === data.rows.length && line.length > 0) {
                renderLegendLine();
            }
        } else {
            renderLegendLine();
        }
    }
}
