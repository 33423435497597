import { createElement as el, Component, ChangeEvent } from "react";

import { CaretRight } from "./caret-right";
import { CaretDown } from "./caret-down";
import { Year } from "../types";
import { Translation, withTranslation, WithTranslationProps } from "react-i18next";

interface Props {
    years: Year[];
    onChange: (idxYear: number) => void;
    includeAll: () => void;
    excludeAll: () => void;
}

interface State {
    isOpen: boolean;
}

class YearFilterImpl extends Component<Props & WithTranslationProps, State> {
    state: State = { isOpen: false };

    private toggleOpen() {
        const isOpen = !this.state.isOpen;
        this.setState({ isOpen });
    }

    private renderArrow() {
        return this.state.isOpen ? el(CaretDown) : el(CaretRight);
    }

    private isAllChecked() {
        return this.props.years.every((year) => year.isIncluded);
    }

    private toggleAll(evt: ChangeEvent<HTMLInputElement>) {
        if (evt.target.checked) {
            this.props.includeAll();
        } else {
            this.props.excludeAll();
        }
    }

    private renderAll() {
        const domId = "chk-year-all";

        return (
            <div key="all" className="filter-panel-item">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        id={domId}
                        checked={this.isAllChecked()}
                        onChange={(evt) => this.toggleAll(evt)}
                    />
                    &nbsp;
                    {this.props.i18n?.t("All", { defaultValue: "All" })}
                </label>
            </div>
        );
    }

    private renderYear(year: Year) {
        const { idx, isIncluded } = year;
        const domId = "chk-year-" + idx;

        return (
            <div key={idx} className="filter-panel-item">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        id={domId}
                        checked={isIncluded}
                        onChange={(evt) => this.props.onChange(idx)}
                    />
                    &nbsp;
                    {year.year}
                </label>
            </div>
        );
    }

    private renderCardBody() {
        if (!this.state.isOpen) {
            return null;
        }

        return (
            <div className="filter-panel">
                {this.renderAll()}
                {this.props.years.map((year) => this.renderYear(year))}
            </div>
        );
    }

    render() {
        return (
            <div>
                <label className="label filter-header" style={{ cursor: "pointer" }} onClick={() => this.toggleOpen()}>
                    <span className="filter-text">
                        <Translation>{(t) => t("Filter_by_year", { defaultValue: "Filter by year" })}</Translation>
                        &nbsp;
                        {this.renderArrow()}
                    </span>
                </label>
                {this.renderCardBody()}
            </div>
        );
    }
}

const YearFilter = withTranslation()(YearFilterImpl);

export { YearFilter };
