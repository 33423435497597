import { fieldSeparator, lineSeparator, parseAmount } from "../data";
import { Row, SimpleData, ContentType, Year } from "../types";
import YAML from "js-yaml";

enum DataToken {
    id = "id",
    text = "text",
    type = "type",
    unit = "unit",
    title = "title",
    line_items = "line_items",
    projections_year = "projections_year",
    legend_text = "legend_text",
    line_items_no_y = "line_items_no_y",
    rows = "rows" // keep rows item as last, to let everything else to parsed before,
}

export const parseRows = (lines: string[], sdType: ContentType = ContentType.barChart): Row[] => {
    const rows: Row[] = [];

    while (true) {
        const line = lines.shift();
        if (!line || !line.trim()) break;

        const [nameRaw, ...strVals] = line.split(fieldSeparator);
        const name = nameRaw.trimEnd();
        const data = strVals.map((val) => {
            if (sdType === ContentType.table) {
                return val;
            }

            const numVal = parseAmount(val);
            return numVal;
        });
        rows.push({ name, data });
    }

    return rows;
};

const tryParseYearsFromHeaders = (headers: string[]): Array<Year> | undefined => {
    if (headers.length <= 0) {
        throw new Error("Headers row is invalid, not enough columns");
    }

    const startYear = parseInt(headers[0], 10);
    if (isNaN(startYear)) {
        return undefined;
    }

    const years: Array<Year> = [];
    headers.forEach((headerStr, i) => {
        const year = parseInt(headerStr, 10);
        if (isNaN(startYear)) {
            throw new Error("Unable to parse year value from header: " + headerStr);
        }

        years.push({
            idx: i,
            year,
            isIncluded: true
        });
    });

    return years;
};

const parseHeaderRow = (lines: string[]): string[] => {
    const headerLine = lines.shift()?.trim() || "";
    if (!headerLine) {
        throw new Error("GHG must contain a header row");
    }

    const headers = headerLine.split(fieldSeparator);
    if (!headers.length) {
        throw new Error(`Headers empty, unable to parse`);
    }
    return headers;
};

const parseRowLines = (lines: string[], sd: Partial<SimpleData>) => {
    const headers = parseHeaderRow(lines);
    sd.headers = headers;

    const years = tryParseYearsFromHeaders(headers);
    if (years) {
        sd.years = years;
    }

    const type = sd.type;

    sd.rows = parseRows(lines, type);
};

export const parseSimpleDataFromYamlDoc = (yamlDoc: any): SimpleData => {
    const sd: Partial<SimpleData> = {};

    Object.values(DataToken).forEach((dataToken) => {
        let val = yamlDoc[dataToken];

        if (dataToken === DataToken.rows) {
            const lines = val.split(lineSeparator);
            parseRowLines(lines, sd);
        } else {
            sd[dataToken] = val;
        }
    });

    return sd as SimpleData;
};

export const parseSimpleDataFromYaml = (strData: string): SimpleData => {
    const doc: any = YAML.load(strData);

    return parseSimpleDataFromYamlDoc(doc);
};
