/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Sector, SectorsData } from "../../details-types";
import { loadSectorsData } from "../data/sectors";
import { PeriodType } from "../types";
import { Spinner } from "./spinner";
import { ActivityContent } from "./activity-content";
import { PieChartContent } from "./pie-chart-content";
import { Link, useMatch } from "react-router-dom";

export interface SectorsChartsProps {
    period: PeriodType;
}

const OVERVIEW_ACTIVITY_ID = "overview_tab";

export const SectorCharts: FC<SectorsChartsProps> = (props: SectorsChartsProps) => {
    const [data, setData] = useState<SectorsData>();
    const sectorMatch = useMatch(`/${props.period}/sector/:sectorName`);
    let activeSectorName = sectorMatch && sectorMatch.params.sectorName;

    const activityMatch = useMatch(`/${props.period}/sector/:sectorName/:activityName`);
    const activeActivityName = activityMatch && activityMatch.params.activityName;
    if (!activeSectorName && activityMatch) {
        activeSectorName = activityMatch.params.sectorName;
    }

    const { t } = useTranslation();

    useEffect(() => {
        const load = async () => {
            const sectorsData = await loadSectorsData();
            setData(sectorsData);
        };

        load();
    });

    if (!data) {
        return <Spinner />;
    }

    const hasItems = (sector: Sector) => {
        const items = props.period === PeriodType.past ? sector.inventory : sector.prognosis;

        return items && items.length > 0;
    };

    const activeSector =
        data.sectors.find((sector) => {
            if (sector.id !== activeSectorName && activeSectorName) return false;

            return hasItems(sector);
        }) || data.sectors.find((sector) => hasItems(sector));

    const menuSource = props.period === PeriodType.past ? activeSector?.inventory : activeSector?.prognosis;

    const activeActivity =
        (menuSource &&
            menuSource.length > 0 &&
            (menuSource.find((activity) => activity.id === activeActivityName) || menuSource[0])) ||
        undefined;

    const activeSectorMenuItems =
        menuSource &&
        menuSource.map((activity) => {
            return (
                <li key={activity.id}>
                    <Link
                        to={`/${props.period}/sector/${activeSectorName}/${activity.id}`}
                        className={activity === activeActivity ? "is-active" : ""}
                    >
                        {t(activity.id, activity.title)}
                    </Link>
                </li>
            );
        });

    const sectorTabLabels = data.sectors.filter(hasItems).map((sector) => {
        return (
            <li key={sector.id} className={`${activeSector?.id === sector.id ? "is-active" : ""}`}>
                <Link to={`/${props.period}/sector/${sector.id}`}>{t(sector.id, sector.title)}</Link>
            </li>
        );
    });

    return (
        <Fragment>
            <h1 className="subtitle">{t("By_sector_title", "By sector")}</h1>
            <div className="sector-tabs-container tabs">
                <ul>{sectorTabLabels}</ul>
            </div>
            <div className="container sectors-charts-section-container columns">
                {activeSectorMenuItems && activeSectorMenuItems.length > 1 && (
                    <div className="activity-menu column is-one-fifth">
                        <aside className="menu">
                            <ul className="menu-list">{activeSectorMenuItems}</ul>
                        </aside>
                    </div>
                )}
                <div className="activity-data column">
                    {activeActivity?.id === OVERVIEW_ACTIVITY_ID ? (
                        <PieChartContent />
                    ) : (
                        activeActivity && (
                            <ActivityContent
                                key={activeActivity.file}
                                file={activeActivity.file}
                                title={t(activeActivity.id, activeActivity.title)}
                            />
                        )
                    )}
                </div>
            </div>
        </Fragment>
    );
};
