import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Cat, CatSpec } from "../types";
import { GenericFilter } from "./generic-filter";
import { cats as allCats } from "../segments";

export interface CatFilterProps {
    cats: Cat[];
    filteredCats: Array<string>;
    onChange: (id: string) => void;
    includeAll: () => void;
    excludeAll: () => void;
}

export const CatFilter: FC<CatFilterProps> = (props) => {
    const { t } = useTranslation();

    const { cats, filteredCats, ...rest } = props;
    return (
        <GenericFilter
            title={t("Filter_by_category", "Filter by category")}
            getSpec={(id) => allCats.get(id) as CatSpec}
            segments={cats}
            filteredSegments={filteredCats}
            {...rest}
        />
    );
};
