import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { loadActivityData } from "../data/sectors";
import { getFileFieldName } from "../shared-utils";
import { SimpleData, ContentType } from "../types";
import { ChartContent } from "./chart-content";
import { MarkdownContent } from "./markdown-content";
import { Spinner } from "./spinner";
import { TableContent } from "./table-content";

export interface GhgContentProps {
    file: string;
}

export const GhgContent: FC<GhgContentProps> = (props: GhgContentProps) => {
    const [data, setData] = useState<SimpleData>();
    const { t } = useTranslation();

    useEffect(() => {
        const load = async () => {
            const activityData = await loadActivityData(props.file);
            setData(activityData);
        };

        load();
    });

    if (!data) {
        return <Spinner />;
    }

    let content: JSX.Element | undefined = undefined;
    if (data.type === ContentType.barChart) {
        content = <ChartContent data={data} />;
    } else if (data.type === ContentType.table) {
        content = <TableContent data={data} />;
    } else if (data.type === ContentType.lineChart) {
        content = <ChartContent data={data} />;
    }

    const description = t(getFileFieldName(data.id, "text"), { defaultValue: data.text || "" });
    const descriptionContent = (description && <MarkdownContent markdown={description} />) || null;
    if (!content) content = <p>Unknown chart type={props.file}</p>;

    return (
        <div className="ghg-content-container">
            {content}
            <div className="is-size-6 content has-text-justified">{descriptionContent}</div>
        </div>
    );
};
