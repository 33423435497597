import { TFunction } from "i18next";
import { FC, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { DetailLevel } from "../types";

interface Props {
    value: DetailLevel;
    includeTotals: boolean;
    onSelect: (val: DetailLevel) => void;
}

const detailLevelNames: { [k: DetailLevel | string]: string } = {
    // t("Total_emissions", { defaultValue: "Total emissions"})
    [DetailLevel.total]: "Total_emissions",
    // t("By_sector", { defaultValue: "By sector"})
    [DetailLevel.sector]: "By_sector",
    // t("By_category", { defaultValue: "By category"})
    [DetailLevel.cat]: "By_category",
    // t("By_sub-category", { defaultValue: "By sub-category"})
    [DetailLevel.subCat]: "By_sub-category"
};

const renderOptions = (includeTotals: boolean, t: TFunction) => {
    const options = [];

    for (let detailLevel in DetailLevel) {
        if (!includeTotals && detailLevel === DetailLevel.total) {
            continue;
        }
        options.push(
            <option key={detailLevel} value={detailLevel}>
                {t(detailLevelNames[detailLevel])}
            </option>
        );
    }

    return options;
};

export const DetailLevelSelect: FC<Props> = ({ value, includeTotals, onSelect }: Props) => {
    const { t } = useTranslation();

    return (
        <div className="field">
            <label className="label">{t("Level_of_detail", { defaultValue: "Level of detail" })}</label>
            <div className="control">
                <div className="select">
                    <select
                        value={value}
                        onChange={(evt: ChangeEvent<HTMLSelectElement>) => onSelect(evt.target.value as DetailLevel)}
                    >
                        {renderOptions(includeTotals, t)}
                    </select>
                </div>
            </div>
        </div>
    );
};
