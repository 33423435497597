import { DynamicPageContentIndex } from "../../details-types";

let dynPageData = new Map<string, DynamicPageContentIndex>();

export const loadDynamicPageIndex = async (pageName: string): Promise<DynamicPageContentIndex> => {
    let pageData = dynPageData.get(pageName);
    if (pageData) {
        return pageData;
    }

    pageData = await import(`../../data/${pageName}/index.json`) as DynamicPageContentIndex;
    dynPageData.set(pageName, pageData);

    return pageData;
};
