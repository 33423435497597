import { createElement as el, FC } from "react";

import { LegendElement } from "../types";
import { LegendItem } from "./legend-item";
import { idxWithoutLulucfColor, idxWithLulucfColor } from "../totals";

interface Props {
    withoutLulucfText: string;
    withLulucfText: string;
}

const renderItem = ({ name, idxColor }: LegendElement, idx: number) => el(LegendItem, { key: idx, idxColor }, name);

export const TotalsLegend: FC<Props> = ({ withoutLulucfText, withLulucfText }) => {
    const items = [
        {
            name: withoutLulucfText,
            idxColor: idxWithoutLulucfColor
        },
        {
            name: withLulucfText,
            idxColor: idxWithLulucfColor
        }
    ];

    return el("div", { id: "main-right" }, el("ul", { className: "legend" }, items.map(renderItem)));
};
