import { TFunction } from "i18next";
import { FC, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { getFileFieldName } from "../shared-utils";

import { GasId, GasesData } from "../types";

interface Props {
    gasesData: GasesData;
    value: string;
    onSelect: (val: GasId) => void;
}

const renderOptions = (gasesData: GasesData, t: TFunction) => {
    const options: unknown[] = [];

    gasesData.forEach((gas, gasId) =>
        options.push(
            <option key={gasId} value={gasId}>
                {t(getFileFieldName(gas.id, "name"), gas.name)} ({t(getFileFieldName(gas.id, "unit"), gas.unit)})
            </option>
        )
    );

    return options;
};

export const GasSelect: FC<Props> = ({ gasesData, value, onSelect }: Props) => {
    const { t } = useTranslation();

    return (
        <div className="field">
            <label className="label">{t("Pollutant", "Pollutant")}</label>
            <div className="control">
                <div className="select">
                    <select
                        value={value}
                        onChange={(evt: ChangeEvent<HTMLSelectElement>) => onSelect(evt.target.value as GasId)}
                    >
                        {renderOptions(gasesData, t)}
                    </select>
                </div>
            </div>
        </div>
    );
};
