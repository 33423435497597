import { assertNever } from "./assert-never";
import { parseRows, parseSimpleDataFromYamlDoc } from "./data/simple-data";
import { cats, sectors, subCats } from "./segments";
import YAML from "js-yaml";

import {
    Sector,
    Cat,
    SubCat,
    GasId,
    GasesData,
    Gas,
    YearlyData,
    DetailLevel,
    Proportion,
    Segment,
    ProportionData,
    PeriodType,
    Measure,
    Row
} from "./types";

interface TokenizedLine<T> {
    token: T;
    rest?: string;
}

export interface Years {
    startYear: number;
    numYears: number;
}

interface SegmentSpec {
    detailLevel: DetailLevel;
    id: string;
    yearlyData: YearlyData;
}

export const lineSeparator = "\n";
const tokenSeparator = ": ";
const yearSeparator = "-";
export const fieldSeparator = "\t";

export const parseToken = (line: string): TokenizedLine<string> => {
    const [token, rawRest] = line.split(tokenSeparator);
    const tokenizedLine: TokenizedLine<string> = { token };

    if (rawRest !== undefined) {
        tokenizedLine.rest = rawRest.trim();
    }

    return tokenizedLine;
};

const isGasId = (id: string): id is GasId => id in GasId;

export const parseYears = (yearsToken: string): Years => {
    const [strStartYear, strEndYear] = yearsToken.split(yearSeparator);
    const startYear = parseInt(strStartYear, 10);
    const endYear = parseInt(strEndYear, 10);
    const numYears = endYear - startYear + 1;
    return { startYear, numYears };
};

const validateSegmentId = (id: string, detailLevel: DetailLevel) => {
    if (detailLevel === DetailLevel.sector) {
        if (!sectors.has(id)) {
            throw new Error("Invalid sector id: " + id);
        }
    } else if (detailLevel === DetailLevel.cat) {
        if (!cats.has(id)) {
            throw new Error("Invalid category id: " + id);
        }
    } else if (detailLevel === DetailLevel.subCat) {
        if (!subCats.has(id)) {
            throw new Error("Invalid sub-category id: " + id);
        }
    } else {
        throw new Error("Invalid detail level: " + detailLevel);
    }
};

const validateSegmentDataCount = (id: string, yearlyData: YearlyData, expectedYearCount: number) => {
    if (yearlyData.length !== expectedYearCount) {
        throw new Error(
            `Data count does not match the number of years for ${id}. Expected ${expectedYearCount}, but got ${yearlyData.length}`
        );
    }
};

export const parseAmount = (str: string): number => {
    const validator = str.match(/\d,\d/g);
    if (validator) {
        throw new Error(`Invalid character "," found in a table cell: ${str}`);
    }

    const floatAmt = parseFloat(str);
    return isNaN(floatAmt) ? 0 : floatAmt;
};

const requiredFields = ["id", "name", "unit"];
const validateFields = (yamlObj: any, fileName: string) => {
    requiredFields.forEach((field) => {
        if (typeof yamlObj[field] === "undefined") throw new Error(`Field ${field} is missing from ${fileName}`);
    });
};

const gasDataCache = new Map<string, Gas>();
const loadGasData = async (periodType: PeriodType, gasId: GasId, measure?: Measure): Promise<Gas> => {
    let path: string = "";

    if (periodType === PeriodType.past) {
        path = `data/past/${gasId}.yml`;
    } else if (periodType === PeriodType.prognosis) {
        path = `data/prognosis/${measure}/${gasId}.yml`;
    } else {
        assertNever(periodType, "period type");
    }

    const cachedGasData = gasDataCache.get(path);
    if (cachedGasData) return cachedGasData;

    const url = require(`../${path}`);
    const response = await fetch(url);
    let strData = await response.text();

    const doc: any = YAML.load(strData);
    validateFields(doc, path);

    const { id, name, unit } = doc;
    const { years, rows, legend_text } = parseSimpleDataFromYamlDoc(doc);
    if (!isGasId(id)) {
        throw new Error("Invalid gas id: " + id);
    }

    const parseSegmentFromRow = (row: Row, expectedYearCount: number): SegmentSpec => {
        let detailLevel: DetailLevel;
        let yearlyData: YearlyData;

        if (row.name.startsWith("    ") || row.name.startsWith("\t\t\t\t")) {
            detailLevel = DetailLevel.subCat;
            yearlyData = row.data;
        } else if (row.name.startsWith("  ") || row.name.startsWith("\t\t")) {
            detailLevel = DetailLevel.cat;
            yearlyData = row.data.slice(1);
        } else {
            detailLevel = DetailLevel.sector;
            yearlyData = row.data.slice(2);
        }

        const id = row.name.trim();
        validateSegmentId(id, detailLevel);
        validateSegmentDataCount(id, yearlyData, expectedYearCount);

        return { detailLevel, id, yearlyData };
    };

    const createSectorsFromRows = (): Sector[] => {
        let sectors: Sector[] = [];
        let detailLevel: DetailLevel = DetailLevel.sector;
        let sector: Sector = { cats: [], id: "", data: [] };
        let cat: Cat = { id: "", data: [], subCats: [] };
        let subCat: SubCat;

        rows.forEach((row) => {
            const segmentSpec = parseSegmentFromRow(row, years.length);
            detailLevel = segmentSpec.detailLevel;

            if (detailLevel === DetailLevel.sector) {
                sector = {
                    id: segmentSpec.id,
                    data: segmentSpec.yearlyData,
                    cats: []
                };

                sectors.push(sector);
            } else if (detailLevel === DetailLevel.cat) {
                cat = {
                    id: segmentSpec.id,
                    data: segmentSpec.yearlyData,
                    subCats: []
                };

                sector.cats.push(cat);
            } else if (detailLevel === DetailLevel.subCat) {
                subCat = { id: segmentSpec.id, data: segmentSpec.yearlyData };
                cat.subCats.push(subCat);
            } else {
                throw new Error("Invalid detail level: " + detailLevel);
            }
        });

        return sectors;
    };

    const createObjFromRows = <T>(dataFieldName: string, requiredFields: string[]): T | undefined => {
        const rawData = doc[dataFieldName];
        if (!rawData) return undefined;

        const lines = rawData.trim().split("\n");
        const rows = parseRows(lines);
        const obj: Partial<T> = {};
        requiredFields.forEach((rowName) => {
            const row = rows.find((r) => r.name === rowName);
            if (!row) {
                throw new Error(`Field ${rowName} not found from the data in: `);
            }
            validateSegmentDataCount(`${id}-${dataFieldName}-${row.name}`, row.data, years.length);
            // @ts-ignore
            obj[row.name] = row.data;
        });

        return obj as T;
    };

    const gasData: Gas = {
        id: id as GasId,
        unit: unit as string,
        name,
        years,
        legend_text,
        sectors: createSectorsFromRows(),
        bunkers: createObjFromRows("memo", ["total", "navigation", "aviation"]),
        totals: createObjFromRows("total", ["withLulucf", "withoutLulucf"])
    };

    gasDataCache.set(path, gasData);

    return gasData;
};

export const loadPastGasesData = async (): Promise<GasesData> => {
    const promises: Promise<Gas>[] = [];

    for (let gasId in GasId) {
        promises.push(loadGasData(PeriodType.past, (GasId as any)[gasId]));
    }

    const arrGases = await Promise.all(promises);
    const gasesData: GasesData = new Map();
    arrGases.forEach((gas) => gasesData.set(gas.id, gas));
    return gasesData;
};

const wemGasIds: GasId[] = [GasId.co2eq, GasId.co2, GasId.ch4, GasId.n2o, GasId.hfc, GasId.sf6];

export const loadPrognosisWemGasesData = async (): Promise<GasesData> => {
    const promises: Promise<Gas>[] = [];

    for (let gasId of wemGasIds) {
        promises.push(loadGasData(PeriodType.prognosis, GasId[gasId], Measure.wem));
    }

    const arrGases = await Promise.all(promises);
    const gasesData: GasesData = new Map();
    arrGases.forEach((gas) => gasesData.set(gas.id, gas));
    return gasesData;
};

const wamGasIds: GasId[] = [GasId.co2eq, GasId.co2, GasId.ch4, GasId.n2o, GasId.hfc, GasId.sf6];

export const loadPrognosisWamGasesData = async (): Promise<GasesData> => {
    const promises: Promise<Gas>[] = [];

    for (let gasId of wamGasIds) {
        promises.push(loadGasData(PeriodType.prognosis, gasId, Measure.wam));
    }

    const arrGases = await Promise.all(promises);
    const gasesData: GasesData = new Map();
    arrGases.forEach((gas) => gasesData.set(gas.id, gas));
    return gasesData;
};

const parseProportionSector = (line: string): Segment => {
    const [id, ...strYearlyData] = line.split(fieldSeparator);
    validateSegmentId(id, DetailLevel.sector);
    const yearlyData = strYearlyData.map(parseAmount);
    return { id, data: yearlyData };
};

const parseProportionSectors = (lines: string[]): ProportionData => {
    const sectors: { [key: string]: YearlyData } = {};

    while (lines.length) {
        const line = (lines.shift() || "").trim();
        const { id, data } = parseProportionSector(line);
        sectors[id] = data;
    }

    return sectors;
};

const parseProportionData = (strData: string): Proportion => {
    const doc: any = YAML.load(strData);

    const { startYear, numYears } = parseYears(doc.years || "");

    const lines = doc.rows
        .split("\n")
        .map((l: string) => l.trim())
        .filter((l: string) => !!l);

    const sectors = parseProportionSectors(lines);
    return {
        startYear,
        numYears,
        sectors
    };
};

let proportionData: Proportion;
export const loadProportionData = async (): Promise<Proportion> => {
    if (proportionData) return proportionData;

    const url = require("../data/past/proportion.yml");
    const response = await fetch(url);
    const strData = await response.text();

    proportionData = parseProportionData(strData);
    return proportionData;
};
