import { FC, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

import { Main } from "./main";

import { PeriodType } from "./types";
import { DynamicContentPage } from "./dynamic-content-page";
import { t } from "i18next";

export const AppRoutes: FC = () => {
    const location = useLocation();

    useEffect(() => {
        function reportLocation() {
            ReactGA.pageview(location.pathname + location.hash + location.search);
        }

        reportLocation();
    }, [location]);

    return (
        <Routes>
            <Route path="/" element={<Main periodType={PeriodType.past} />} />
            <Route path="/inventory/*" element={<Main periodType={PeriodType.past} />} />
            <Route path="/projections/*" element={<Main periodType={PeriodType.prognosis} />} />
            <Route path="/projects/*" element={<DynamicContentPage
                dataDir="projects"
                urlPrefix="/projects"
                title={t("Projects", "Development Projects")}
                />}
            />
            <Route path="/emission-factors/*" element={<DynamicContentPage
                dataDir="emission-factors"
                urlPrefix="/emission-factors"
                title={t("EmissionFactors", "Emission Factors")}
                />}
            />
            <Route path="/economic-indicators-ghg/*" element={<DynamicContentPage
                dataDir="economic-indicators-ghg"
                urlPrefix="/economic-indicators-ghg"
                title={t("EconomicIndicatorsGHG", "Economic Indicators and GHG emissions")}
                />}
            />
        </Routes>
    );
};
