import { cloneDeep } from "lodash";
import { Component, Fragment } from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { createSearchParams, NavigateFunction } from "react-router-dom";
import { assertNever } from "../assert-never";
import { loadPastGasesData, loadPrognosisWamGasesData, loadPrognosisWemGasesData, loadProportionData } from "../data";
import { createXlsx } from "../export-xlsx";
import { getSegmentSpec } from "../segments";
import { getLegendItemFieldName } from "../shared-utils";
import {
    Cat,
    DetailLevel,
    Gas,
    GasesData,
    GasId,
    Measure,
    PeriodType,
    Proportion,
    Row,
    Sector,
    Segment,
    SimpleData,
    ContentType,
    SubCat,
    TooltipProps,
    Year
} from "../types";
import { CatFilter } from "./cat-filter";
import { DetailLevelSelect } from "./detail-level-select";
import { GasSelect } from "./gas-select";
import { GenericChart } from "./generic-chart";
import { MeasureTabBar } from "./measure-tab-bar";
import { SectorFilter } from "./sector-filter";
import { SegmentLegend } from "./segment-legend";
import { Spinner } from "./spinner";
import { SubCatFilter } from "./subcat-filter";
import { Tooltip } from "./tooltip";
import { TotalsLegend } from "./totals-legend";
import { WithRouter } from "./with-router";
import { YearFilter } from "./year-filter";

const defaultParams = {
    measure: Measure.wem,
    level: DetailLevel.total,
    gas: GasId.co2eq,
    sector: [],
    cat: [],
    sub: []
};

export interface MainChartProps {
    periodType: PeriodType;
}

interface MainChartState {
    pastGasesData: GasesData;
    prognosisWemGasesData: GasesData;
    prognosisWamGasesData: GasesData;
    years: Year[];
    proportion?: Proportion;
    tooltip?: TooltipProps;
    showingPeriodType: PeriodType; // TODO: not so nice, remove?
}

class MainChartImpl extends Component<MainChartProps & WithTranslationProps, MainChartState> {
    state: MainChartState = {
        pastGasesData: new Map(),
        prognosisWamGasesData: new Map(),
        prognosisWemGasesData: new Map(),
        years: [],
        showingPeriodType: PeriodType.past
    };

    private searchParams?: URLSearchParams;
    private navigate?: NavigateFunction;
    private exportAsPngCb?: Function;

    static getDerivedStateFromProps(props: Partial<MainChartProps & WithTranslationProps>, state: MainChartState): Partial<MainChartState> | null {
        if (props.periodType === state.showingPeriodType) return null;

        const nextState = {
            measure: Measure.wem,
            selectedGasId: GasId.co2eq,
            showingPeriodType: props.periodType
        };

        return {
            ...state,
            ...nextState
        };
    }

    componentDidMount() {
        this.ensureData(this.props.periodType);
    }

    componentDidUpdate(prevProps: MainChartProps, prevState: MainChartState) {
        if (prevState.showingPeriodType !== this.state.showingPeriodType) {
            this.ensureData(this.state.showingPeriodType);
        }
    }

    private async ensureData(periodType: PeriodType) {
        if (periodType === PeriodType.prognosis) {
            this.loadPrognosisData();
        } else {
            this.loadInventoryData();
        }
    }

    get filterParams() {
        return {
            gas: (this.searchParams?.get("gas") as GasId) || defaultParams.gas,
            level: (this.searchParams?.get("level") as DetailLevel) || defaultParams.level,
            measure: (this.searchParams?.get("measure") as Measure) || defaultParams.measure,
            sector: this.searchParams?.getAll("sector") || defaultParams.sector,
            cat: this.searchParams?.getAll("cat") || defaultParams.cat,
            sub: this.searchParams?.getAll("sub") || defaultParams.sub
        };
    }

    private setExportAsPngCallback = (cb: Function) => {
        this.exportAsPngCb = cb;
    };

    private updateFilterParams(vals: Partial<typeof this.filterParams>, replace: boolean = false) {
        const params = (!replace && this.searchParams) || new URLSearchParams();

        Object.entries(vals).forEach(([index, value]) => {
            if (Array.isArray(value)) {
                params.delete(index);
                if (!value.length) {
                    value.push("none");
                }

                value.forEach((item) => {
                    params.append(index, item);
                });
            } else if (value !== undefined) {
                params.set(index, value);
            } else {
                params.delete(index);
            }
        });

        if (!this.navigate) throw new Error("navigate() not set");
        this.navigate(`/${this.props.periodType}/?${createSearchParams(params)}`, { replace: true });
    }

    private async loadInventoryData() {
        const pastGasesPromise = loadPastGasesData();
        const proportionPromise = loadProportionData();

        const [pastGasesData, proportion] = await Promise.all([pastGasesPromise, proportionPromise]);

        const newState = { pastGasesData, proportion };
        this.setState(newState, () => {
            const years = this.getYears();
            this.setState({ years });
        });
    }

    private async loadPrognosisData() {
        const prognosisWemGasesPromise = loadPrognosisWemGasesData();
        const prognosisWamGasesPromise = loadPrognosisWamGasesData();

        const [prognosisWemGasesData, prognosisWamGasesData] = await Promise.all([
            prognosisWemGasesPromise,
            prognosisWamGasesPromise
        ]);

        const newState = { prognosisWemGasesData, prognosisWamGasesData };
        this.setState(newState, () => {
            const years = this.getYears();
            this.setState({ years });
        });
    }

    private getYears(): Year[] {
        const gas = this.getSelectedGas();
        const years = gas.years.map((y) => {
            return { ...y, isIncluded: true };
        });

        return years;
    }

    private getGas(gasId: GasId): Gas {
        const { periodType } = this.props;
        const { pastGasesData, prognosisWemGasesData, prognosisWamGasesData } = this.state;
        const { measure } = this.filterParams;
        if (!pastGasesData || !prognosisWamGasesData || !prognosisWemGasesData) {
            throw new Error("No data");
        }

        if (periodType === PeriodType.past) {
            return pastGasesData.get(gasId) as Gas;
        } else if (periodType === PeriodType.prognosis) {
            if (measure === Measure.wem) {
                return prognosisWemGasesData.get(gasId) as Gas;
            } else if (measure === Measure.wam) {
                return prognosisWamGasesData.get(gasId) as Gas;
            } else {
                assertNever(measure, "measure");
            }
        } else {
            assertNever(periodType, "period type");
        }

        throw new Error("Unexpected");
    }

    private getSelectedGas(): Gas {
        return this.getGas(this.filterParams.gas);
    }

    private getFilteredSectors(): Sector[] {
        const { sector: filteredSectors } = this.filterParams;

        const gas = this.getSelectedGas();
        const includedSectors = gas.sectors.filter(
            (sector) => filteredSectors.length === 0 || filteredSectors.find((sectorId) => sectorId === sector.id)
        );
        return includedSectors;
    }

    private getFilteredCats(): Cat[] {
        const { cat: filteredCats } = this.filterParams;

        const sectors = this.getFilteredSectors();
        let includedCats: Cat[] = [];
        sectors.forEach(
            ({ cats }) =>
                (includedCats = includedCats.concat(
                    cats.filter((cat) => filteredCats.length === 0 || filteredCats.find((id) => id === cat.id))
                ))
        );

        return includedCats;
    }

    private getFilteredSubCats(): SubCat[] {
        const { sub: filteredSubCats } = this.filterParams;

        const cats = this.getFilteredCats();
        let includedSubCats: SubCat[] = [];
        cats.forEach(
            ({ subCats }) =>
                (includedSubCats = includedSubCats.concat(
                    subCats.filter(
                        (subCat) => filteredSubCats.length === 0 || filteredSubCats.find((id) => id === subCat.id)
                    )
                ))
        );

        return includedSubCats;
    }

    private getApplicableCats(): Cat[] {
        const sectors = this.getFilteredSectors();
        let allCats: Cat[] = [];
        sectors.forEach(({ cats }) => (allCats = allCats.concat(cats)));

        return allCats;
    }

    private getApplicableSubCats(): SubCat[] {
        const cats = this.getApplicableCats();
        let allSubCats: SubCat[] = [];
        cats.forEach(({ subCats }) => (allSubCats = allSubCats.concat(subCats)));

        return allSubCats;
    }

    private extractSegments(): Segment[] {
        const { level: detailLevel } = this.filterParams;

        if (detailLevel === DetailLevel.sector || detailLevel === DetailLevel.total) {
            return this.getFilteredSectors();
        } else if (detailLevel === DetailLevel.cat) {
            return this.getFilteredCats();
        } else if (detailLevel === DetailLevel.subCat) {
            return this.getFilteredSubCats();
        } else {
            assertNever(detailLevel, "detail level");
        }

        throw new Error("Unexpected");
    }

    private setMeasure = (measure: Measure) => {
        this.updateFilterParams({ measure, gas: GasId.co2eq });
    };

    private setDetailLevel = (level: DetailLevel) => {
        this.updateFilterParams({ level });
    };

    private setGas = (gasId: GasId) => {
        const { level: detailLevel } = this.filterParams;
        const years = this.getYears();

        const gas = this.getGas(gasId);

        const newDetailLevel = detailLevel === DetailLevel.total && !gas.totals ? DetailLevel.sector : detailLevel;

        this.setState({ years });

        this.updateFilterParams({ gas: gasId, level: newDetailLevel });
    };

    private getGasesDataVarName(): "pastGasesData" | "prognosisWemGasesData" | "prognosisWamGasesData" {
        const { measure } = this.filterParams;
        const { periodType } = this.props;

        if (periodType === PeriodType.past) {
            return "pastGasesData";
        } else if (periodType === PeriodType.prognosis) {
            if (measure === Measure.wem) {
                return "prognosisWemGasesData";
            } else if (measure === Measure.wam) {
                return "prognosisWamGasesData";
            } else {
                assertNever(measure, "measure");
            }
        } else {
            assertNever(periodType, "period type");
        }

        throw new Error("Unexpected");
    }

    private getGasesData(): GasesData {
        const varName = this.getGasesDataVarName();
        if (!this.state[varName]) throw new Error("Unexpected");

        return this.state[varName];
    }

    private toggleSectorIncluded = (id: string) => {
        const { gas, sector: sectors } = this.filterParams;
        const gasesDataVarName = this.getGasesDataVarName();

        const newGasesData = this.state[gasesDataVarName];
        let newSectors =
            (sectors.length > 0 && sectors) || (newGasesData.get(gas) as Gas).sectors.map((sector) => sector.id);

        const sectorIx = newSectors.findIndex((sector) => sector === id);
        if (sectorIx >= 0) {
            newSectors.splice(sectorIx, 1);
        } else {
            newSectors.push(id);
        }

        this.updateFilterParams({ sector: newSectors });
    };

    private includeAllSectors = () => {
        this.updateFilterParams({ sector: undefined });
    };

    private excludeAllSectors = () => {
        this.updateFilterParams({ sector: [] });
    };

    private toggleCatIncluded = (id: string) => {
        const { cat: cats } = this.filterParams;

        let newCats = (cats.length > 0 && cats) || this.getApplicableCats().map((cat) => cat.id);
        const itemIx = newCats.findIndex((item) => item === id);
        if (itemIx >= 0) {
            newCats.splice(itemIx, 1);
        } else {
            newCats.push(id);
        }

        this.updateFilterParams({ cat: newCats });
    };

    private includeAllCats = () => {
        this.updateFilterParams({ cat: undefined });
    };

    private excludeAllCats = () => {
        this.updateFilterParams({ cat: [] });
    };

    private toggleSubCatIncluded = (id: string) => {
        const { sub: subCats } = this.filterParams;

        let newSubCats = (subCats.length > 0 && subCats) || this.getApplicableSubCats().map((subCat) => subCat.id);
        const itemIx = newSubCats.findIndex((item) => item === id);
        if (itemIx >= 0) {
            newSubCats.splice(itemIx, 1);
        } else {
            newSubCats.push(id);
        }

        this.updateFilterParams({ sub: newSubCats });
    };

    private includeAllSubCats = () => {
        this.updateFilterParams({ sub: undefined });
    };

    private excludeAllSubCats = () => {
        this.updateFilterParams({ sub: [] });
    };

    private toggleYearIncluded = (idxYear: number) => {
        const years = cloneDeep(this.state.years);
        const year = years[idxYear];

        if (!year) {
            throw new Error("Error toggling year: invalid year index: " + idxYear);
        }

        year.isIncluded = !year.isIncluded;
        this.setState({ years });
    };

    private includeAllYears = () => {
        const years = cloneDeep(this.state.years);
        years.forEach((year) => (year.isIncluded = true));
        this.setState({ years });
    };

    private excludeAllYears = () => {
        const years = cloneDeep(this.state.years);
        years.forEach((year) => (year.isIncluded = false));
        this.setState({ years });
    };

    public setTooltip = (tooltip?: TooltipProps) => {
        this.setState({ tooltip });
    };

    private renderMeasureTabBar() {
        if (this.props.periodType !== PeriodType.prognosis) {
            return null;
        }

        const { measure } = this.filterParams;

        return <MeasureTabBar activeTabId={measure} onSelect={this.setMeasure} />;
    }

    private renderSectorFilter() {
        if (this.filterParams.level === DetailLevel.total) {
            return;
        }

        const { sectors } = this.getSelectedGas();
        return (
            <SectorFilter
                sectors={sectors}
                filteredSectors={this.filterParams.sector}
                onChange={this.toggleSectorIncluded}
                includeAll={this.includeAllSectors}
                excludeAll={this.excludeAllSectors}
            />
        );
    }

    private renderCategoryFilter() {
        if (this.filterParams.level !== DetailLevel.cat && this.filterParams.level !== DetailLevel.subCat) {
            return;
        }

        const cats = this.getApplicableCats();
        return (
            <CatFilter
                cats={cats}
                filteredCats={this.filterParams.cat}
                onChange={this.toggleCatIncluded}
                includeAll={this.includeAllCats}
                excludeAll={this.excludeAllCats}
            />
        );
    }

    private renderSubCategoryFilter() {
        if (this.filterParams.level !== DetailLevel.subCat) {
            return;
        }

        const cats = this.getApplicableSubCats();
        return (
            <SubCatFilter
                subCats={cats}
                filteredSubCats={this.filterParams.sub}
                onChange={this.toggleSubCatIncluded}
                includeAll={this.includeAllSubCats}
                excludeAll={this.excludeAllSubCats}
            />
        );
    }

    private getLabelTextForTotals = (withLulucf: boolean) => {
        const { name, id, legend_text } = this.getSelectedGas();

        if (withLulucf) {
            const legendKey = getLegendItemFieldName(id, "withLulucf");

            return (
                this.props.i18n?.t(legendKey, legend_text?.["withLulucf"] || "") ||
                this.props.i18n?.t("gas_with_lulucf", {
                    defaultValue: "Total {{name}} with {{name}} from LULUCF",
                    name
                }) ||
                "With"
            );
        } else {
            const legendKey = getLegendItemFieldName(id, "withoutLulucf");

            return (
                this.props.i18n?.t(legendKey, legend_text?.["withoutLulucf"] || "") ||
                this.props.i18n?.t("gas_without_lulucf", {
                    defaultValue: "Total {{name}} without {{name}} from LULUCF",
                    name
                }) ||
                "With"
            );
        }
    };

    private renderGeneralLegend() {
        const { periodType } = this.props;
        const { level: detailLevel } = this.filterParams;
        const { pastGasesData, prognosisWemGasesData: prognosisGasesData } = this.state;

        if (
            (periodType === PeriodType.past && pastGasesData.size === 0) ||
            (periodType === PeriodType.prognosis && prognosisGasesData.size === 0)
        ) {
            return null;
        }

        const gas = this.getSelectedGas();

        if (detailLevel === DetailLevel.total) {
            if (!gas.totals) {
                return null;
            }

            const withoutLulucfText = this.getLabelTextForTotals(false);
            const withLulucfText = this.getLabelTextForTotals(true);

            return <TotalsLegend withoutLulucfText={withoutLulucfText} withLulucfText={withLulucfText} />;
        }

        const segments = this.extractSegments();
        const ids = segments.map(({ id }) => id);
        return <SegmentLegend legendText={gas.legend_text} detailLevel={detailLevel} ids={ids} />;
    }

    private renderTooltip() {
        const { tooltip } = this.state;

        if (!tooltip || !tooltip.text) return;

        return <Tooltip {...tooltip} />;
    }

    render() {
        return (
            <WithRouter>
                {({ searchParams, setSearchParams, navigate }) => {
                    this.searchParams = searchParams;
                    this.navigate = navigate;
                    return (
                        <div className="main-chart-container container box">
                            {this.renderMeasureTabBar()}
                            {this.renderGeneralChart()}
                            {this.renderTooltip()}
                        </div>
                    );
                }}
            </WithRouter>
        );
    }

    private createSimpleData(): SimpleData {
        const { years } = this.state;
        const { level: detailLevel } = this.filterParams;
        const gas = this.getSelectedGas();
        const { totals } = gas;
        const includedYearIdxs = new Set(
            years.map((year, index) => (year.isIncluded ? index : -1)).filter((v) => v !== -1)
        );
        const includedYears = years.filter((year) => year.isIncluded);

        const type = detailLevel === DetailLevel.total ? ContentType.lineChart : ContentType.barChart;
        let rows: Row[];
        if (detailLevel === DetailLevel.total && totals) {
            const withoutLulucfText = this.getLabelTextForTotals(false);
            const withLulucfText = this.getLabelTextForTotals(true);

            rows = [
                {
                    name: withoutLulucfText || "N/A",
                    data: totals.withoutLulucf.filter((v, index) => includedYearIdxs.has(index))
                },
                {
                    name: withLulucfText || "N/A",
                    data: totals.withLulucf.filter((v, index) => includedYearIdxs.has(index))
                }
            ];
        } else {
            const segments = this.extractSegments();
            rows = segments.map((segment) => {
                const segmentSpec = getSegmentSpec(detailLevel, segment.id);
                const name = this.props.i18n?.t(`header.${segment.id}`, segmentSpec.name) || segmentSpec.name;
                return {
                    name,
                    colorIdx: segmentSpec.idxColor,
                    data: segment.data.filter((v, index) => includedYearIdxs.has(index))
                };
            });
        }

        const sd: SimpleData = {
            id: "main",
            unit: gas.unit,
            type,
            text: "",
            years: includedYears,
            rows
        };

        return sd;
    }

    private renderGeneralChart() {
        const { periodType, i18n } = this.props;
        const { gas: gasId, level: detailLevel } = this.filterParams;

        const { pastGasesData, prognosisWemGasesData: prognosisGasesData, years } = this.state;

        if (
            (periodType === PeriodType.past && pastGasesData.size === 0) ||
            (periodType === PeriodType.prognosis && prognosisGasesData.size === 0) ||
            years.length === 0
        ) {
            return <Spinner />;
        }

        const gasesData = this.getGasesData();
        const gas = this.getSelectedGas();
        const { totals } = gas;
        const includeTotals = Boolean(totals);
        const segments = this.extractSegments();

        const chartData = this.createSimpleData();
        const colors =
            detailLevel === DetailLevel.total
                ? undefined
                : segments.map((segment) => {
                      const spec = getSegmentSpec(detailLevel, segment.id);
                      return spec.idxColor;
                  });

        return (
            <Fragment>
                <div className="columns">
                    <div className="column is-narrow">
                        <GasSelect gasesData={gasesData} value={gasId} onSelect={this.setGas} />
                    </div>
                    <div className="column is-narrow">
                        <DetailLevelSelect
                            value={detailLevel}
                            includeTotals={includeTotals}
                            onSelect={this.setDetailLevel}
                        />
                    </div>
                </div>
                {this.renderSectorFilter()}
                {this.renderCategoryFilter()}
                {this.renderSubCategoryFilter()}
                <YearFilter
                    years={years}
                    onChange={this.toggleYearIncluded}
                    includeAll={this.includeAllYears}
                    excludeAll={this.excludeAllYears}
                />
                <div className="columns is-vcentered">
                    <div className="column is-narrow">
                        <GenericChart
                            data={chartData}
                            colorIndexes={colors}
                            setTooltip={this.setTooltip}
                            onExportPng={this.setExportAsPngCallback}
                        />
                        <div className="is-flex">
                            <div className="m-auto">
                                <button
                                    className="button is-small mx-1"
                                    onClick={() => this.exportAsPngCb && this.exportAsPngCb()}
                                >
                                    {i18n?.t("Export_as_image", "Export as image")}
                                </button>
                                <button className="button is-small mx-1" onClick={() => createXlsx(chartData)}>
                                    {i18n?.t("Export_as_xlsx", "Export as XLSX")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="column">{this.renderGeneralLegend()}</div>
                </div>
            </Fragment>
        );
    }
}

const MainChart = withTranslation()(MainChartImpl);
export { MainChart };
