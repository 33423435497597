import { FC, useEffect, useState } from "react";
import { loadProportionData } from "../data";
import { DetailLevel, Proportion } from "../types";
import { PieChart } from "./pie-chart";
import { SegmentLegend } from "./segment-legend";
import { Spinner } from "./spinner";

export interface PieChartContentProps {}

export const PieChartContent: FC<PieChartContentProps> = () => {
    const [proportion, setProportion] = useState<Proportion>();

    useEffect(() => {
        const load = async () => {
            const propsData = await loadProportionData();
            setProportion(propsData);
        };

        load();
    });

    if (!proportion) {
        return <Spinner />;
    }

    return (
        <div className="columns is-vcentered">
            <div className="column is-narrow">
                <PieChart proportion={proportion} />
            </div>
            <div className="column">
                <SegmentLegend detailLevel={DetailLevel.sector} ids={Object.keys(proportion.sectors)} />
            </div>
        </div>
    );
};
