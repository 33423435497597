import { createElement as el, FC } from "react";

export const CaretRight: FC<{}> = () =>
    el(
        "svg",
        {
            width: "1em",
            height: "1em",
            viewBox: "0 0 16 16",
            className: "bi bi-caret-right-fill",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        },
        el("path", {
            d:
                "M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 " +
                "1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
        })
    );
