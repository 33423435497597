import { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLocalizationKeyFromString } from "../shared-utils";
import { SectorSpec, Segment } from "../types";
import { CaretDown } from "./caret-down";
import { CaretRight } from "./caret-right";

export interface GenericFilterProps {
    segments: Segment[];
    filteredSegments: Array<string>;
    getSpec: (specId: string) => SectorSpec;
    onChange: (id: string) => void;
    includeAll: () => void;
    excludeAll: () => void;
    title: string;
}

export const GenericFilter: FC<GenericFilterProps> = (props) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState<boolean>(false);

    const isAllChecked = () => {
        const { filteredSegments: filteredCats } = props;
        return (
            filteredCats.length === 0 || props.segments.every((cat) => filteredCats.find((catId) => catId === cat.id))
        );
    };

    const toggleAll = (evt: ChangeEvent<HTMLInputElement>) => {
        if (evt.target.checked) {
            props.includeAll();
        } else {
            props.excludeAll();
        }
    };

    const renderSegment = (segment: Segment, idx: number) => {
        const { filteredSegments, onChange, getSpec } = props;
        const spec = getSpec(segment.id);

        return (
            <div key={idx} className="filter-panel-item">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={Boolean(
                            filteredSegments.length === 0 || filteredSegments.find((id) => id === segment.id)
                        )}
                        onChange={() => onChange(segment.id)}
                    />
                    &nbsp;
                    {t(`header.${spec.id}`) || t(getLocalizationKeyFromString(spec.id), spec.name)}
                </label>
            </div>
        );
    };

    const content = !isOpen ? null : (
        <div className="filter-panel">
            <div key="all" className="filter-panel-item">
                <label className="checkbox">
                    <input type="checkbox" checked={isAllChecked()} onChange={toggleAll} />
                    &nbsp;
                    {t("All", "All")}
                </label>
            </div>
            {props.segments.map((cat, idx) => renderSegment(cat, idx))}
        </div>
    );

    return (
        <div>
            <label className="label filter-header" style={{ cursor: "pointer" }} onClick={() => setOpen(!isOpen)}>
                <span className="filter-text">
                    {props.title}
                    &nbsp;
                    {isOpen ? <CaretDown /> : <CaretRight />}
                </span>
            </label>
            {content}
        </div>
    );
};
