import { createContext, Dispatch } from "react";
import { AppState, initialState } from "./app-state";
import { actions, Actions } from "./actions";

export type DispatchType = Dispatch<(state: AppState) => AppState>;

export interface AppContextType {
    state: AppState;
    actions: Actions;
    dispatch: DispatchType;
}

export const AppContext = createContext<AppContextType>({
    state: initialState,
    actions,
    dispatch: () => {
        throw new Error("Dispatcher not set");
    }
});
