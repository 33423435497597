import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { loadActivityMarkdown } from "../data/sectors";
import { GhgContent } from "./ghg-content";
import { MarkdownContent } from "./markdown-content";
import { Spinner } from "./spinner";
export interface ActivityContentProps {
    file: string;
    title: string;
}

export const ActivityContent: FC<ActivityContentProps> = (props: ActivityContentProps) => {
    const [markdown, setMarkdown] = useState<string>();
    const fileExt = props.file.split(".").pop()?.toLocaleLowerCase();
    const isMarkdownFile = fileExt === "md";

    const { i18n } = useTranslation();
    useEffect(() => {
        const load = async () => {
            if (isMarkdownFile) {
                const markdownData = await loadActivityMarkdown(props.file, i18n.language);
                setMarkdown(markdownData);
            } else {
                setMarkdown(undefined);
            }
        };

        load();
    });

    let content: JSX.Element | undefined = undefined;

    if (markdown) {
        content = <MarkdownContent markdown={markdown} />;
    } else if (isMarkdownFile) {
        content = <Spinner />;
    } else {
        content = <GhgContent file={props.file} />;
    }

    return (
        <Fragment>
            <h2 className="subtitle">{props.title}</h2>
            {content}
        </Fragment>
    );
};
