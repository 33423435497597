export const colors = [
    "#0072ce",
    "#f0a321",
    "#003087",
    "#8dc8e8",
    "#e76000",
    "#b9d9eb",
    "#41b6e6",
    "#ba432a",
    "#c8c9c7",
    "#97999b",
    "#eb8316",
    "#53565a",
    "#b1b3b3",
    "#f1be9f",
    "#75787b",
    "#2c2a29",
    "#81d4af",
    "#297950",
    "#d4456b",
    "#4e9370",
    "#74cd81",
    "#89007a",
    "#afe1c0",
    "#246855"
];

export const black = colors[15];

export const getConsecutiveColor = (idx: number): string => {
    const idxColor = idx % colors.length;
    return colors[idxColor];
};
