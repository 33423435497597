import { FC } from "react";
import { useTranslation } from "react-i18next";
interface Props {
    startYear: number;
    numYears: number;
    // This is idxYear not year
    value: number;
    onSelect: (idxYear: number) => void;
}

export const YearSelect: FC<Props> = ({ startYear, numYears, value, onSelect }: Props) => {
    const { t } = useTranslation();

    return (
        <div className="field">
            <label className="label">{t("Year", { defaultValue: "Year" })}</label>
            <div className="control">
                <input
                    className="slider is-fullwidth is-info is-large is-circle"
                    step="1"
                    min={startYear}
                    max={startYear + numYears - 1}
                    value={startYear + value}
                    type="range"
                    onChange={(evt) => onSelect(parseInt(evt.target.value) - startYear)}
                />
            </div>
        </div>
    );
};
