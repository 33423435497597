import { FC, ReactElement } from "react";
import { NavigateFunction, URLSearchParamsInit, useNavigate, useSearchParams } from "react-router-dom";

export type SetSearchParamsFn = (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
        | {
              replace?: boolean | undefined;
              state?: any;
          }
        | undefined
) => void;

export interface WithRouterChildrenProps {
    searchParams: URLSearchParams;
    setSearchParams: SetSearchParamsFn;
    navigate: NavigateFunction;
}

export interface WithRouterProps {
    children: (props: WithRouterChildrenProps) => ReactElement;
}

export const WithRouter: FC<WithRouterProps> = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    return children({ searchParams, setSearchParams, navigate });
};
