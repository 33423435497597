import { createElement as el, FC } from "react";

import { getConsecutiveColor } from "../colors";

interface Props {
    idxColor: number;
}

export const ColorBox: FC<Props> = ({ idxColor }) =>
    el("div", { className: "color-box", style: { backgroundColor: getConsecutiveColor(idxColor) } });
