import { Component, Fragment } from "react";

import { PeriodType } from "./types";

import { SectorCharts } from "./comp/sector-charts";
import { MainChart } from "./comp/main-chart";

interface Props {
    periodType: PeriodType;
}

class MainImpl extends Component<Props> {
    render() {
        return (
            <Fragment>
                <div className="section">
                    <MainChart periodType={this.props.periodType} />
                </div>
                <div className="section">
                    <SectorCharts period={this.props.periodType} />
                </div>
            </Fragment>
        );
    }
}

const Main = MainImpl;

export { Main };
