import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Measure } from "../types";

interface Props {
    activeTabId: Measure;
    onSelect: (tabId: Measure) => void;
}

export const MeasureTabBar: FC<Props> = ({ activeTabId, onSelect }) => {
    const { t } = useTranslation();
    return (
        <div className="measure-radios control mb-2">
            <label className="label">{t("Measures", "Measures")}</label>
            <label className="radio">
                <input
                    type="radio"
                    name="measure"
                    value={Measure.wem}
                    checked={activeTabId === Measure.wem}
                    onChange={(e) => onSelect(e.currentTarget.value as Measure)}
                />
                &nbsp;
                {t("With_existing_measures", "With existing measures")}
            </label>
            <br />
            <label className="radio">
                <input
                    type="radio"
                    name="measure"
                    value={Measure.wam}
                    checked={activeTabId === Measure.wam}
                    onChange={(e) => onSelect(e.currentTarget.value as Measure)}
                />
                &nbsp;
                {t("With_additional_measures", "With additional measures")}
            </label>
        </div>
    );
};
