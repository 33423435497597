import ReactGA from "react-ga";

let GA_ID = "";
if (process.env.GA_ID) {
    GA_ID = process.env.GA_ID;
} else if (window.location.hostname === "test.kasvuhoonegaasid.ee") {
    GA_ID = "Y";
} else if (window.location.hostname === "dev.kasvuhoonegaasid.ee") {
    GA_ID = "Z";
} else {
    // main production
    GA_ID = "X";
}

export function initGoogleAnalytics(id: string = GA_ID) {
    ReactGA.initialize(id);
}
