export const formatGraphStepNumber = (orig: number): string => {
    const strOrig = orig.toString();

    if (orig < 10000) {
        return strOrig;
    } else {
        const strOrigLength = strOrig.length;
        const parts: string[] = [];
        let idx = strOrigLength - 3;

        while (idx > -3) {
            const part = strOrig.substring(idx, idx + 3);
            parts.unshift(part);
            idx -= 3;
        }

        return parts.join(" ");
    }
};

export const formatBarChartTooltipNumber = (orig: number): string => {
    if (orig === 0) {
        return "";
    } else if (orig > -1 && orig < 1) {
        return orig.toPrecision(1);
    } else {
        return orig.toFixed(1);
    }
};

export const formatTableCell = (orig: number | string) => {
    if (typeof orig === "string") {
        return orig;
    }

    if (typeof orig === "number") {
        return orig.toFixed(3);
    }

    return "N/A";
};
