import { FC, Fragment, useState } from "react";
import { SimpleData, TooltipProps } from "../types";
import { SimpleLegend } from "./simple-legend";
import { GenericChart } from "./generic-chart";
import { Tooltip } from "./tooltip";
import { useTranslation } from "react-i18next";
import { getFileFieldName, getLocalizationKeyFromString } from "../shared-utils";

export interface ChartContentProps {
    data: SimpleData;
}

export const ChartContent: FC<ChartContentProps> = ({ data }) => {
    const [tooltip, setTooltip] = useState<TooltipProps>();
    const { t } = useTranslation();

    const renderTooltip = () => {
        if (!tooltip) return;
        return <Tooltip {...tooltip} />;
    };

    const labels = data.rows.map(({ name }) => t(getLocalizationKeyFromString(name), { defaultValue: name }));
    const title = t(getFileFieldName(data.id, "title"), { defaultValue: data.title || "" });

    return (
        <Fragment>
            <div className="columns is-vcentered">
                <div className="column auto-scroll is-narrow">
                    {title && <h6 className="has-text-centered is-size-6 mb-0">{title}</h6>}
                    <GenericChart data={data} setTooltip={setTooltip} />
                </div>
                <div className="column">
                    <SimpleLegend labels={labels} />
                </div>
            </div>
            {renderTooltip()}
        </Fragment>
    );
};
