export enum PeriodType {
    past = "inventory",
    prognosis = "projections"
}

export enum GasId {
    co2eq = "co2eq",
    co2 = "co2",
    ch4 = "ch4",
    n2o = "n2o",
    hfc = "hfc",
    pfc = "pfc",
    sf6 = "sf6"
}

export enum DetailLevel {
    total = "total",
    sector = "sector",
    cat = "cat",
    subCat = "subCat"
}

export enum Measure {
    wem = "wem",
    wam = "wam"
}

export type YearlyData = Array<number | string>;

export interface SectorSpec {
    id: string;
    name: string;
    idxColor: number;
}

export interface CatSpec extends SectorSpec {
    sectorId: string;
}

export interface SubCatSpec extends SectorSpec {
    catId: string;
}

export interface LegendElement {
    name: string;
    idxColor: number;
}

export interface Segment {
    id: string;
    data: YearlyData;
}

export interface SubCat extends Segment {}

export interface Cat extends Segment {
    subCats: SubCat[];
}

export interface Sector extends Segment {
    cats: Cat[];
}

export interface GasTotals {
    withoutLulucf: YearlyData;
    withLulucf: YearlyData;
}

export interface Bunkers {
    total: YearlyData;
    aviation: YearlyData;
    navigation: YearlyData;
}

export interface LegendText {
    [k: string]: string;
}

export interface Gas {
    id: GasId;
    name: string;
    unit: string;
    years: Array<Year>;
    sectors: Sector[];
    bunkers?: Bunkers;
    totals?: GasTotals;
    legend_text?: LegendText;
}

export type GasesData = Map<GasId, Gas>;

export type ProportionData = { [key: string]: YearlyData };

export interface Proportion {
    startYear: number;
    numYears: number;
    sectors: ProportionData;
}

export interface Year {
    idx: number;
    year: number;
    isIncluded: boolean;
}

export interface TooltipProps {
    x: number;
    y: number;
    text: string;
}

export interface Row {
    name: string;
    colorIdx?: number;
    data: YearlyData;
}

export enum ContentType {
    barChart = "barchart",
    lineChart = "linechart",
    table = "table"
}

export interface SimpleData {
    id: string;
    years: Array<Year>;
    rows: Row[];
    text?: string;
    type?: ContentType;
    headers?: string[];
    unit?: string;
    title?: string;
    line_items?: string[];
    line_items_no_y?: boolean;
    projections_year?: number;
    legend_text?: LegendText;
}

export interface Bounds {
    min: number;
    max: number;
}

export interface Bar {
    top: number;
    bottom: number;
    left: number;
    right: number;
    amt: number;
    segment?: Segment;
    row?: Row;
}

export interface LineSegment {
    x: number;
    y: number;
    amt: number;
    row?: Row;
    text?: string;
}
