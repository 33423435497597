import { TFunction } from "i18next";
import { createElement as el, FC } from "react";
import { useTranslation } from "react-i18next";

import { cats, sectors, subCats } from "../segments";
import { getLegendItemFieldName, getLocalizationKeyFromString } from "../shared-utils";
import { DetailLevel, LegendText, SectorSpec } from "../types";
import { LegendItem } from "./legend-item";

interface Props {
    detailLevel: DetailLevel;
    ids: string[];
    legendText?: LegendText;
}

const renderSegment = (detailLevel: DetailLevel, id: string, t: TFunction, legendText?: LegendText) => {
    let segmentSpec: SectorSpec;

    if (detailLevel === DetailLevel.sector) {
        segmentSpec = sectors.get(id) as SectorSpec;
    } else if (detailLevel === DetailLevel.cat) {
        segmentSpec = cats.get(id) as SectorSpec;
    } else if (detailLevel === DetailLevel.subCat) {
        segmentSpec = subCats.get(id) as SectorSpec;
    } else {
        throw new Error("Invalid level of detail: " + detailLevel);
    }

    const { name, idxColor } = segmentSpec;
    const legendKey = getLegendItemFieldName(id, segmentSpec.id);

    let label = t(`header.${id}`) ||
      t(legendKey, legendText?.[segmentSpec.id]) ||
      t(getLocalizationKeyFromString(id), name) || "";

    return el(LegendItem, { key: id, idxColor }, label);
};

export const SegmentLegend: FC<Props> = ({ detailLevel, ids, legendText }) => {
    const { t } = useTranslation();
    return el(
        "div",
        { id: "main-right" },
        el(
            "ul",
            { className: "legend" },
            ids.map((id) => renderSegment(detailLevel, id, t, legendText))
        )
    );
};
