import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sector, SectorSpec } from "../types";
import { GenericFilter } from "./generic-filter";
import { sectors as allSectors } from "../segments";

export interface SectorFilterProps {
    sectors: Sector[];
    filteredSectors: Array<string>;
    onChange: (id: string) => void;
    includeAll: () => void;
    excludeAll: () => void;
}

export const SectorFilter: FC<SectorFilterProps> = (props) => {
    const { t } = useTranslation();

    const { sectors, filteredSectors, ...rest } = props;
    return (
        <GenericFilter
            title={t("Filter_by_sector", "Filter by sector")}
            getSpec={(id) => allSectors.get(id) as SectorSpec}
            segments={sectors}
            filteredSegments={filteredSectors}
            {...rest}
        />
    );
};
