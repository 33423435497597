import React, { Reducer, Suspense, useReducer } from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";

import "./localization-setup";
import { Layout } from "./layout";
import { AppState, initialState } from "./app-state";
import { AppContext } from "./context";
import { actions } from "./actions";

import { initGoogleAnalytics } from "./init-google-analytics";

initGoogleAnalytics();

type AppStateReducerAction = (state: AppState) => AppState;
type AppStateReducer = Reducer<AppState, AppStateReducerAction>;

const stateReducer: AppStateReducer = (prevState, action) => {
    return action(prevState);
};

const RootComponent = () => {
    const [state, dispatch] = useReducer<AppStateReducer>(stateReducer, initialState);

    return (
        <AppContext.Provider value={{ state, actions, dispatch }}>
            <Suspense fallback="...">
                <Layout />
            </Suspense>
        </AppContext.Provider>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <RootComponent />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
