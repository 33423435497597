import { SectorsData } from "../../details-types";
import { SimpleData } from "../types";
import { parseSimpleDataFromYaml } from "./simple-data";

let sectorsData: SectorsData;

export const loadSectorsData = async (): Promise<SectorsData> => {
    if (sectorsData) {
        return sectorsData;
    }

    sectorsData = await import("../../data/detail/index.json") as SectorsData;
    return sectorsData;
};

const activityData = new Map<string, SimpleData>();
export const loadActivityData = async (file: string): Promise<SimpleData> => {
    let data = activityData.get(file);

    if (!data) {
        const url = require(`../../${file}`);
        const response = await fetch(url);
        const textData = await response.text();

        if (file.endsWith(".yml")) {
            data = parseSimpleDataFromYaml(textData);
        } else {
            throw new Error("Unknown file format: " + file);
        }
        activityData.set(file, data);

        if (!data.rows || data.rows.length === 0) throw new Error("Unable to parse rows from " + file);
    }

    return data;
};

const activityMarkdown = new Map<string, string>();
export const loadActivityMarkdown = async (file: string, currentLang: string): Promise<string> => {
    let localizedFile: string;
    if (currentLang === "en") {
        localizedFile = file;
    } else {
        localizedFile = file.replace(".md", `_${currentLang}.md`);
    }

    let data = activityMarkdown.get(localizedFile);
    if (!data) {
        let url: string;
        try {
            url = require(`../../${localizedFile}`);
        }
        catch (e) {
            url = require(`../../${file}`);
        }

        const response = await fetch(url);

        data = await response.text();
        activityMarkdown.set(localizedFile, data);
    }

    return data;
};
