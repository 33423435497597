/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";
import { Link, useMatch } from "react-router-dom";
import { LanguageSelect } from "./comp/lang-select";

export const Header = () => {
    const { t } = useTranslation();
    const inventoryMatch = useMatch("/inventory/*");
    const rootMatch = useMatch("/");
    const isInventoryActive = Boolean(inventoryMatch || rootMatch);

    const projectionsMatch = useMatch("/projections/*");
    const projectsMatch = useMatch("/projects/*");
    const efMatch = useMatch("/emission-factors/*");
    const eigMatch = useMatch("/economic-indicators-ghg/*");

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item" href="#">
                    <img src="co2_pilt.png" alt="CO₂" />
                </a>
            </div>

            <div className="navbar-menu is-active">
                <div className="navbar-start">
                    <Link to="/inventory/" className={`is-tab navbar-item ${isInventoryActive ? "is-active" : ""}`}>
                        {t("Inventory", "Inventory")}
                    </Link>
                    <Link
                        to="/projections/"
                        className={`is-tab navbar-item ${Boolean(projectionsMatch) ? "is-active" : ""}`}
                    >
                        {t("Projections", "Projections")}
                    </Link>
                    <Link
                        to="/projects/"
                        className={`is-tab navbar-item ${Boolean(projectsMatch) ? "is-active" : ""}`}
                    >
                        {t("Projects", "Development Projects")}
                    </Link>
                    <Link
                        to="/emission-factors/"
                        className={`is-tab navbar-item ${Boolean(efMatch) ? "is-active" : ""}`}
                    >
                        {t("EmissionFactors", "Emission Factors")}
                    </Link>
                    <Link
                        to="/economic-indicators-ghg/"
                        className={`is-tab navbar-item ${Boolean(eigMatch) ? "is-active" : ""}`}
                    >
                        {t("EconomicIndicatorsGHG", "Economic Indicators and GHG emissions")}
                    </Link>
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <button className="button is-light" disabled>
                                {t("Log_in", { defaultValue: "Log in" })}
                            </button>
                        </div>
                    </div>
                    <div className="navbar-item">
                        <LanguageSelect />
                    </div>
                </div>
            </div>
        </nav>
    );
};
