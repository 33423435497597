import { Component } from "react";

export interface CanvasProps
    extends React.DetailedHTMLProps<React.CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement> {
    width: number;
    height: number;
    canvasRef: React.RefObject<HTMLCanvasElement>;
}

function getRatio() {
    return window.devicePixelRatio;
}

export class Canvas extends Component<CanvasProps> {
    componentDidMount() {
        const { canvasRef } = this.props;
        const context = canvasRef.current?.getContext("2d");
        const ratio = getRatio();
        context?.scale(ratio, ratio);
    }

    render() {
        const { width, height, canvasRef, ...rest } = this.props;
        const ratio = getRatio();

        const cW = width * ratio;
        const cY = height * ratio;

        return (
            <canvas
                width={cW}
                height={cY}
                style={{ width: width + "px", height: height + "px" }}
                ref={canvasRef}
                {...rest}
            />
        );
    }
}
