import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Pseudo from "i18next-pseudo";
import LanguageDetector from "i18next-browser-languagedetector";
import yaml from "js-yaml";

const pseudoLanguageEnabled = false;

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(
        new Pseudo({
            enabled: pseudoLanguageEnabled
        })
    )
    .use({
        type: "backend",
        read(language: string, namespace: never, callback: (errorValue: unknown, translations: null) => void) {
            import(`../locales/${language}.yml`)
                .then((resources) => {
                    const url = resources.default;
                    return fetch(url)
                        .then((response) => response.text())
                        .then((content) => {
                            const parsed: any = yaml.load(content);
                            callback(null, parsed);
                        });
                })
                .catch((error) => {
                    callback(error, null);
                });
        }
    })
    .init({
        fallbackLng: "en",
        debug: false,
        interpolation: {
            escapeValue: false
        },
        postProcess: ["pseudo"]
    });
