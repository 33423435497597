import { SimpleData } from "./types";

const AUTHOR = "www.kasvuhoonegaasid.ee";
const SHEET_NAME = "Sheet1";

export async function createXlsx(data: SimpleData) {
    const { utils, writeFile } = await import("xlsx");

    const createdAt = new Date();
    const wb = utils.book_new();
    wb.Props = {
        Title: data.title,
        Author: AUTHOR,
        CreatedDate: createdAt
    };

    const createRowFirstCell = (cells: Array<unknown>, firstCell = "") => {
        const r: Array<unknown> = [firstCell];
        return r.concat(cells);
    };

    const rows: Array<Array<unknown>> = [];

    // headers if present
    if (data.headers) {
        rows.push(createRowFirstCell(data.headers));
    }
    // years
    else if (data.years) {
        rows.push(createRowFirstCell(data.years.map((y) => y.year)));
    }

    // data from SD
    const dataRows = data.rows.map((row) => {
        return createRowFirstCell(row.data, row.name);
    });
    rows.push(...dataRows);

    rows.push([]);
    rows.push([`${AUTHOR} - ${createdAt.toLocaleDateString()}`]);

    const sheet = utils.aoa_to_sheet(rows);
    wb.SheetNames.push(SHEET_NAME);
    wb.Sheets[SHEET_NAME] = sheet;

    writeFile(wb, "chart.xlsx");
}
