const invalidYamlChars = /[^A-Za-z0-9_-]/g;

export function getLocalizationKeyFromString(header: string) {
    return "auto." + header.replaceAll(invalidYamlChars, "_").toLowerCase();
}

export function getFileFieldName(id: string, fieldName: string): string {
    return `${id}.${fieldName}`;
}

export function getLegendItemFieldName(id: string, fieldName: string) {
    return `${id}.legend_text.${fieldName.replaceAll(invalidYamlChars, "_").toLowerCase()}`;
}
