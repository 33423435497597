import { FC } from "react";
import { useTranslation } from "react-i18next";

export const LanguageSelect: FC = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className="select language-select">
            <select
                title={t("Select_language", "Select language")}
                onChange={(e) => i18n.changeLanguage(e.target.value)}
                value={i18n.language}
            >
                <option value="en">ENG</option>
                <option value="et">EE</option>
            </select>
        </div>
    );
};
